// Parent class for all imaginable targets to export metrics to
// such as Google Analytics, Cloudwatch, Postgres etc
//
// Each publisher implements a prepare method to transform the metrics
// in a target format and a send method to publish this data
// to the target analytics system
export default class MetricsPublisher {
  // Prepare the CSM metrics to be published (e.g. reduce to a subset, transform shape)
  // This default implementation just prepares a copy of the CSM object
  //
  // @param{MetricData} csmData The clientside metrics that were collected on the page
  //    and are ready to be processed by the metrics published
  prepare(csmData) {
    this.data = csmData;
  }

  // Sends the prepared data to the target system
  send() {}

  publish(csmData) {
    this.prepare(csmData);
    if (!this.data.isEmpty()) {
      this.send();
    }
  }
}
