import { Controller } from '@hotwired/stimulus';

/**
 * Directory Search controller
 */
export default class extends Controller {
  static targets = ['hiddenBusinessCategory', 'form'];

  searchAll() {
    this.hiddenBusinessCategoryTarget.disabled = true;
    this.formTarget.submit();
  }
}
