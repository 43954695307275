import Splide from '@splidejs/splide';
import { Controller } from '@hotwired/stimulus';

/**
 * slider controller: connects the element to a Splide instance.
 *
 * https://splidejs.com/options/
 */
export default class extends Controller {
  static targets = ['sliderElement', 'endAlert'];

  connect() {
    const sliderElement = this.hasSliderElementTarget
      ? this.sliderElementTarget
      : this.element;
    sliderElement.classList.add('connected');

    this.splide = new Splide(sliderElement, {
      pagination: false,
    }).mount();

    this.splide.on('arrows:updated', (_prev, _next, _prevIndex, nextIndex) => {
      if (this.hasEndAlertTarget) {
        this.renderEndAlert(nextIndex);
      }
    });
  }

  renderEndAlert(nextIndex) {
    if (nextIndex <= 0) {
      this.endAlertTarget.classList.remove('hide');
    } else {
      this.endAlertTarget.classList.add('hide');
    }
  }

  disconnect() {
    if (this.splide) {
      this.splide.destroy();
      delete this.splide;
    }
  }
}
