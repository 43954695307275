import { Controller } from '@hotwired/stimulus';
import MarkerClusterer from '@googlemaps/markerclustererplus';

import { publish, subscribe, unsubscribe, events } from 'lib/event_bus';

/**
 * map controller: show a Google map.
 * Will not load map unless `= component('map/head')` has been included on the page.
 *
 * Subscribes to these events:
 * - google_maps_callback: initializes the map when the Google Maps script has loaded
 * - map_toggle_polygon_selected: selects the polygon with the specified id
 *
 * Publishes these events:
 * - map_click_polygon: when a polygon click event occurs
 * - map_mouseover_polygon: when a polygon click mouseover occurs
 * - map_mouseout_polygon: when a polygon click mouseout occurs
 *
 * There are several overlapping zoom/bounds-related values that are used in the
 * following priority:
 * 1. bounds: a 2D array of lat/lng points
 * 2. markers and (if fitToPolygons is true) polygons: the bounds will be stretched
 *    to display all of these map features
 * 3. zoom in mapOptions: a zoom level (e.g. 1 for world, 18 for close up)
 */
export default class MapController extends Controller {
  static targets = ['map', 'container'];

  static values = {
    bounds: Array,
    fitToPolygons: { type: Boolean, default: true }, // fit map bounds to polygons
    lat: Number,
    lng: Number,
    mapOptions: Object,
    markers: Array,
    markerClusters: Array,
    // Array of objects with keys for id, coordinates, selected (optional), data (optional)
    multipleSelect: Boolean,
    polygons: Array,
  };

  initialize() {
    // Global config
    this.config = {
      zoom: 14,
      mapTypeControl: false,
      streetViewControl: false,
      scaleControl: false,
      mapTypeId: 'roadmap', // google.maps.MapTypeId.ROADMAP (might not be loaded yet)
      styles: [
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'off' }],
        },
      ],
      ...this.mapOptionsValue,
    };
  }

  connect() {
    this.polygons = {}; // track polygons by id

    subscribe(
      events.MAP_TOGGLE_POLYGON_SELECTED,
      (event) => {
        this.togglePolygonSelected(this.polygonForId(event.detail.id));
      },
      { subscriber: this },
    );

    subscribe(
      events.MAP_CLEAR_POLYGONS_SELECTED,
      () => {
        this.clearPolygonsSelected();
      },
      { subscriber: this },
    );

    // If google maps hasn't yet loaded
    if (this.google) {
      this.initializeMap();
    } else {
      subscribe(events.GOOGLE_MAPS_CALLBACK, this.initializeMap.bind(this), {
        subscriber: this,
      });
    }
  }

  disconnect() {
    unsubscribe(this);
    delete this.polygons;
    delete this.map;
  }

  initializeMap() {
    // Bail early if the map has already been initialized
    if (this.map) {
      return;
    }

    const options = {
      center: new this.google.maps.LatLng(this.latValue, this.lngValue),
      ...this.config,
    };

    this.map = new this.google.maps.Map(this.mapTarget, options);
    // This bounds will be used to set the bounds of the map.  It will be
    // extended for each marker and polygon added in this initialization.
    const bounds = new this.google.maps.LatLngBounds();

    // Create markers for each element in the markers data attr
    if (this.hasMarkersValue) {
      this.markersValue.forEach((markerData) => {
        const marker = this.addMarker(markerData);

        // Listen for clicks
        this.google.maps.event.addListener(marker, 'click', () => {
          this.clickMarker(marker, options);
        });

        // Extend bounds
        const position = marker && marker.getPosition();
        if (position && this.markersValue.length > 1) {
          bounds.extend(position);
        }
      });
    }

    // Create clustered markers
    if (this.hasMarkerClustersValue) {
      const markers = [];
      this.markerClustersValue.forEach((markerData) => {
        // Initialize marker but don't add it to map until all are initialized
        // and can be added as clusters
        const marker = this.addMarker(markerData, { map: null });
        markers.push(marker);

        // If the markerData specifies a count, add more identical markers
        if (markerData.count && markerData.count > 1) {
          markers.push(
            ...Array.from({ length: markerData.count - 1 }, () =>
              this.addMarker(markerData),
            ),
          );
        }

        // Extend bounds
        const position = marker && marker.getPosition();
        if (position) {
          bounds.extend(position);
        }
      });

      /* eslint-disable no-new */
      new MarkerClusterer(this.map, markers, { imagePath: '/assets/map/m' });
      /* eslint-enable no-new */
    }

    // Create polygons for each lat lng array in the polygons data attr
    if (this.hasPolygonsValue) {
      this.polygonsValue.forEach((polygonData) => {
        this.addPolygon(polygonData);

        if (this.fitToPolygonsValue) {
          this.coordinatesToPath(polygonData.coordinates).forEach((latLng) => {
            bounds.extend(latLng);
          });
        }
      });
    }

    if (!bounds.isEmpty()) {
      this.map.fitBounds(bounds);
    }

    // Use optional pre-calculated bounds value
    const { boundsValue } = this;
    if (boundsValue && boundsValue.length) {
      this.map.fitBounds(
        new this.google.maps.LatLngBounds(
          new this.google.maps.LatLng(boundsValue[0][0], boundsValue[0][1]),
          new this.google.maps.LatLng(boundsValue[1][0], boundsValue[1][1]),
        ),
      );
    }
  }

  addMarker(options) {
    let { lat, lng } = options;

    // Try to geocode by address if missing lat and lng
    if ((!lat || !lng) && options.address) {
      const location = this.locationForAddress(options.address);

      if (location) {
        lat = location.lat();
        lng = location.lng();
      }
    }

    if (!lat || !lng) {
      return null;
    }

    const position = new this.google.maps.LatLng(lat, lng);

    const marker = new this.google.maps.Marker({
      map: this.map,
      position,
      ...options,
    });

    return marker;
  }

  addPolygon({ id, coordinates, selected = false, ...options }) {
    const styleOptions = selected ? this.styles.selected : this.styles.default;

    const polygon = new this.google.maps.Polygon({
      coordinates, // custom property
      selected, // custom property
      paths: this.coordinatesToPath(coordinates),
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.1,
      ...styleOptions,
      ...options,
    });

    polygon.setMap(this.map);
    this.polygons[id] = polygon;

    this.google.maps.event.addListener(polygon, 'click', (event) => {
      this.clickPolygon(event, id, polygon);
    });

    this.google.maps.event.addListener(polygon, 'mouseover', (event) => {
      this.mouseoverPolygon(event, id, polygon);
    });

    this.google.maps.event.addListener(polygon, 'mouseout', (event) => {
      this.mouseoutPolygon(event, id, polygon);
    });

    return polygon;
  }

  polygonForId(id) {
    return this.polygons[id];
  }

  clearPolygonsSelected() {
    Object.values(this.polygons).forEach((polygon) => {
      polygon.selected = false;
      polygon.setOptions(this.styles.default);
    });
  }

  togglePolygonSelected(polygon) {
    if (this.multipleSelectValue) {
      polygon.selected = !polygon.selected;

      const styleOptions = polygon.selected
        ? this.styles.selected
        : this.styles.default;

      polygon.setOptions(styleOptions);
    } else {
      this.clearPolygonsSelected();
      polygon.selected = true;
      polygon.setOptions(this.styles.selected);
    }
  }

  clickPolygon(event, id, polygon) {
    const data = { id, polygon };
    publish(events.MAP_CLICK_POLYGON, data, { on: this.element });
  }

  mouseoverPolygon(event, id, polygon) {
    if (!polygon.selected && polygon.selectable !== false) {
      polygon.setOptions(this.styles.selected);
    }

    const data = { id, polygon };
    publish(events.MAP_MOUSEOVER_POLYGON, data, { on: this.element });
  }

  mouseoutPolygon(event, id, polygon) {
    if (!polygon.selected && polygon.selectable !== false) {
      polygon.setOptions(this.styles.default);
    }

    const data = { id, polygon };
    publish(events.MAP_MOUSEOUT_POLYGON, data, { on: this.element });
  }

  clickMarker(/* marker, options */) {
    // do nothing by default
  }

  /**
   * Converts a 2D array of lat, lng values to an array of google.maps.LatLng
   * objects.
   *
   * @param coordinates {Array<Hash>} array of hashes with lat and lng keys
   * @returns {Array<google.maps.LatLng>}
   */
  coordinatesToPath(coordinates) {
    return coordinates.map(([lat, lng]) => {
      return new this.google.maps.LatLng(lat, lng);
    });
  }

  boundsForPolygon(polygon) {
    const bounds = new this.google.maps.LatLngBounds();
    const paths = polygon.getPaths();

    paths.forEach((path) => {
      path.forEach((vertex) => {
        bounds.extend(vertex);
      });
    });

    return bounds;
  }

  locationForAddress(address) {
    const geocoder = new this.google.maps.Geocoder();
    let location;

    geocoder.geocode({ address }, (results, status) => {
      if (status === this.google.maps.GeocoderStatus.OK) {
        location = results[0].geometry.location;
      }
    });

    return location;
  }

  /**
   * Default colors used by styles.
   */
  get colors() {
    return {
      fill: '#aa4643',
      stroke: '#ff0000',
    };
  }

  /**
   * Default styles used by polygons.
   */
  get styles() {
    return {
      default: {
        strokeColor: this.colors.stroke,
        fillColor: this.colors.fill,
        fillOpacity: 0.5,
      },
      selected: {
        strokeColor: this.colors.stroke,
        fillColor: this.colors.fill,
        fillOpacity: 0.8,
      },
    };
  }

  get google() {
    return window.google;
  }
}
