import { Controller } from '@hotwired/stimulus';

/**
 * list--filter-items controller: filters a list using text from an input field
 * so that only filterable targets whose inner text matches the input's value
 * are shown when filtering is applied.
 *
 * This should be applied to the .dropdown element if being integrated with
 * Bootstrap dropdown events (e.g. hidden.bs.dropdown).
 */
export default class extends Controller {
  static targets = ['input', 'filterable', 'hideWhileFiltering'];

  filter(event) {
    const term = event.target.value.trim();

    if (term) {
      this.showMatching(term);
    } else {
      this.showAll();
    }
  }

  focusInput() {
    if (this.hasInputTarget) {
      this.inputTarget.focus();
    }
  }

  /**
   * Shows all filterable and hideWhileFiltering targets.
   */
  showAll() {
    if (this.hasInputTarget) {
      this.inputTarget.value = '';
    }

    this.filterableTargets.forEach((target) => target.classList.remove('hide'));

    this.toggleHideWhileFilteringTargets(false);
  }

  /**
   * Hides filterable targets that don't match the given term, and hides all
   * hideWhileFiltering targets.
   *
   * @param term {String} the text to match against
   */
  showMatching(term) {
    this.toggleHideWhileFilteringTargets(true);

    this.filterableTargets.forEach((target) => {
      const isMatch = this.matches(term, target.innerText);
      target.classList.toggle('hide', !isMatch);
    });
  }

  /**
   * Hide or show all hideWhileFiltering targets.
   *
   * @param force {Boolean} if true, hides them; if false, shows them.
   */
  toggleHideWhileFilteringTargets(force) {
    this.hideWhileFilteringTargets.forEach((target) =>
      target.classList.toggle('hide', force),
    );
  }

  /**
   * Returns true if the given term is a case-insensitive substring of the given text.
   *
   * @param term {String}
   * @param text {String}
   * @returns {Boolean}
   */
  matches(term, text) {
    if (!term || !text) {
      return false;
    }

    return text.toLowerCase().includes(term.toLowerCase());
  }
}
