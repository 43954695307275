import './polyfills';

import Rails from '@rails/ujs';
import { Stimulus } from 'init/stimulus/stimulus';

import './images';

import './bootstrap/bootstrap';

// Rails app startup
Rails.start();

// Stimulus app startup
const stimulus = Stimulus.start();

export { Rails, stimulus };
