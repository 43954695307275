import { Controller } from '@hotwired/stimulus';

/**
 * field-length-counter controller: shows a count of characters in the field
 *
 * There are two ways max lengths can be configured:
 * 1. (Static) Pass in maxlength and optionally warnlength. This is the default usage
 *    when the fields do not need to be dynamically changed on the page
 * 2. (Dynamic) Pass in a configuration map and a key to the active configuration in the map, e.g:
 *
 *      {
 *        ad_basic: { max: 250, warn: 240, },
 *        ad_premium: { max: 500, warn: 490, },
 *        ...
 *      }
 *
 *    To update the lengths dynamically, you can attach an event name that the controller listens
 *    to. In the event you can set the new active config to be used by the controller.
 */
export default class extends Controller {
  static targets = ['field', 'counter'];

  static values = {
    maxlength: Number,
    warnlength: Number,
    config: Object,
    activeconfig: String,
    updateevent: String,
  };

  static classes = ['warning'];

  connect() {
    this.config = { ...this.configValue };

    this.updateLengthConfig({ detail: { key: this.activeconfigValue } });

    // check if the controller can receive update events for the length configuration
    if (this.hasUpdateeventValue) {
      $(document).on(this.updateeventValue, (event, activeConfigKey) => {
        this.updateLengthConfig(activeConfigKey);
      });
    }
  }

  disconnect() {
    $(document).off(this.updateeventValue);
  }

  // updates the controller active max/warn length.
  updateLengthConfig({ detail: { key } }) {
    this.maxLength = this.maxlengthValue;
    this.warnLength = this.warnlengthValue || this.maxLength;

    const configEntry = this.config[key];
    if (configEntry) {
      this.maxLength = configEntry.max;
      this.warnLength = configEntry.warn || this.maxLength;
    }

    this.update();
  }

  update() {
    const count = this.fieldTarget.value.length;

    const warningClass = this.hasWarningClass
      ? this.warningClass
      : 'text-danger';

    this.counterTarget.textContent = `${count} / ${this.maxLength}`;
    this.counterTarget.classList.toggle(warningClass, count >= this.warnLength);
  }
}
