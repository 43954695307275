/**
 * Show or clear field validation from an input.
 */
export default class FieldValidation {
  // Bootstrap form validation states (`warning` is a custom state created in
  // the FPF variables and implies a potential error but not one that should
  // prevent form submission).
  static states = ['valid', 'invalid', 'warning'];

  constructor(element) {
    this.element = element;
  }

  /**
   * Show the given message in a feedback element and apply the validation state
   * to the input element.
   *
   * @param {string} message The feedback message to show.  If it contains
   *    sanitized HTML, use the html option.
   * @param {string} state The validation state.
   * @param {boolean} html If true, the message will be applied as innerHTML
   *    rather than textContent.  Should only be used for known-safe content.
   */
  show(message, { state = 'invalid', html = false } = {}) {
    this.clear();
    this.element.classList.add(this.stateClass(state));

    let feedbackElement = this.findFeedbackElement(state);
    if (!feedbackElement) {
      feedbackElement = this.createFeedbackElement(state);
    }

    if (html) {
      feedbackElement.innerHTML = message;
    } else {
      feedbackElement.textContent = message;
    }
  }

  /**
   * Clear all validation states from the element.  Removes all validation
   * classes from the input and removes all feedback elements.
   */
  clear() {
    this.states.forEach((state) => {
      this.element.classList.remove(this.stateClass(state));
      const feedbackElement = this.findFeedbackElement(state);
      if (feedbackElement) {
        feedbackElement.remove();
      }
    });
  }

  get states() {
    return FieldValidation.states;
  }

  stateClass(state) {
    return `is-${state}`; // Bootstrap form validation class for inputs
  }

  feedbackClass(state) {
    return `${state}-feedback`; // Bootstrap form validation class for feedback
  }

  /**
   * Find the first feedback element after the input element that matches the
   * expected feedback state class.
   */
  findFeedbackElement(state) {
    const feedbackClass = this.feedbackClass(state);

    let sibling = this.element.nextElementSibling;

    while (sibling) {
      if (sibling.classList.contains(feedbackClass)) {
        return sibling;
      }
      sibling = sibling.nextElementSibling;
    }

    return null;
  }

  /**
   * Create a feedback element after the input element with the expected
   * feedback state class.
   */
  createFeedbackElement(state) {
    const feedbackElement = document.createElement('div');
    feedbackElement.classList.add(this.feedbackClass(state));

    this.element.parentNode.insertBefore(
      feedbackElement,
      this.element.nextSibling,
    );

    return feedbackElement;
  }
}
