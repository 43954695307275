import { Controller } from '@hotwired/stimulus';

/**
 * react controller: abstract base class to render a react app.
 */
export default class extends Controller {
  static targets = ['reactRoot'];

  // Subclass should implement a connect method like this, substituting in the
  // appropriate webpackChunkName and index component.  The index component
  // should export:
  //  - `createRoot`: import { createRoot } from 'react-dom/client'
  //  - `component`: the root react component
  //
  //
  // connect() {
  //   import(
  //     /* webpackChunkName: "foo" */ './foo/index'
  //   ).then((reactModule) => {
  //     this.initializeReact(reactModule);
  //   });
  // }

  initializeReact(reactModule) {
    this.root = reactModule.createRoot(this.reactRootTarget);

    this.root.render(
      /* eslint-disable-next-line react/react-in-jsx-scope */
      <reactModule.component {...this.props} />,
    );
  }

  // Subclass may pass a hash of props to the rendered component.
  get props() {
    return {};
  }

  disconnect() {
    if (this.ReactDOM) {
      this.root.unmount();
      delete this.ReactDOM;
    }
  }
}
