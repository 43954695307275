import { Controller } from '@hotwired/stimulus';

/**
 * user-post controller.
 */
export default class extends Controller {
  static targets = ['closed'];

  /**
   * Toggle display of "closed" element.
   */
  toggleClosed() {
    this.closedTargets.forEach((closed) => closed.classList.toggle('show'));
  }
}
