import { Controller } from '@hotwired/stimulus';

/**
 * cable--cable-stream-status controller: show an offlineIndicator target when
 * any of the turbo streamElement targets have disconnected from their web sockets.
 *
 * The streamElement targets can be rendered with the turbo_stream_from helper,
 * which connects Turbo Streams, which manages the "connected" attribute when
 * the elements are connected to their web sockets.
 *
 * Based on:
 * https://github.com/hotwired/turbo/issues/1261
 */
export default class extends Controller {
  static targets = ['streamElement', 'offlineIndicator'];

  static values = {
    autoStart: { type: Boolean, default: true },
  };

  connect() {
    if (this.autoStartValue) {
      this.start();
    }
  }

  disconnect() {
    this.stop();
  }

  start() {
    if (!this.hasStreamElementTarget) return;
    if (!this.hasOfflineIndicatorTarget) return;

    setTimeout(() => this.showStatus(), 3000);

    this.observer = new MutationObserver(() => {
      if (this.isOnline) {
        this.showStatus();
      } else {
        // Use a delay to prevent flickering, e.g. on page reload
        setTimeout(() => this.showStatus(), 3000);
      }
    });

    this.streamElementTargets.forEach((target) => {
      this.observer.observe(target, {
        attributeFilter: ['connected'],
      });
    });
  }

  stop() {
    this.observer?.disconnect();
  }

  showStatus() {
    if (this.hasOfflineIndicatorTarget) {
      this.offlineIndicatorTarget.classList.toggle('d-none', this.isOnline);
    }
  }

  /**
   * Returns true if all the streamElement targets have the "connected" attribute.
   */
  get isOnline() {
    return this.streamElementTargets.every((element) =>
      element.hasAttribute('connected'),
    );
  }
}
