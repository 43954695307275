import { Controller } from '@hotwired/stimulus';
import { startCase } from 'lodash/string';

const toArray = (value) => {
  if (!value) {
    return [];
  }

  if (typeof value === 'string') {
    return value.split(/\s+/);
  }

  return value;
};

const profilePlanTypeAttr = 'data-profiles--fields-profile-plan-type-value';
const profilePlanTierAttr = 'data-profiles--fields-profile-plan-tier-value';

/**
 * profiles--fields controller: control which fields are visible based on
 * profile plan.
 */
export default class extends Controller {
  static targets = [
    'profilePlanField',
    'titleField',
    'profilePlanDependent',
    'governmentNameContainer',
    'profileTypeLabel',
  ];

  static values = {
    profilePlanTier: String,
    profilePlanType: String,
  };

  connect() {
    this.toggleElements();
  }

  /**
   * Toggle showing elements based on the selected profile plan and title.
   */
  toggleElements() {
    // Toggle display of all profile plan type and tier related elements based
    // on whether they match the selected plan type / tier.
    this.profilePlanDependentTargets.forEach((element) => {
      const elementTypes = toArray(element.getAttribute(profilePlanTypeAttr));
      const elementTiers = toArray(element.getAttribute(profilePlanTierAttr));

      const active =
        (!elementTypes.length || elementTypes.includes(this.profilePlanType)) &&
        (!elementTiers.length || elementTiers.includes(this.profilePlanTier));

      this.toggle(element, active);
    });

    // For government profiles, toggle display of the profile name element
    // based on whether the title is "other".
    if (
      this.profilePlanType === 'government' &&
      this.hasGovernmentNameContainerTarget
    ) {
      this.toggle(
        this.governmentNameContainerTarget,
        this.profileTitle === 'other',
      );
    }

    // Update labels dependent on profile plan type. ex. 'business description' vs
    // 'nonprofit description'
    this.profileTypeLabelTargets.forEach((label) => {
      const labelString = [
        this.profilePlanType,
        label.getAttribute('data-name'),
      ].join(' ');
      label.innerHTML = startCase(labelString);
    });
  }

  /**
   * Toggle showing the given element, and disabling all fields within it.
   */
  toggle(element, active) {
    element.classList.toggle('show', active);

    element.querySelectorAll('input, textarea, select').forEach((field) => {
      field.disabled = !active;
    });
  }

  /**
   * Get the currently selected profile plan type from the data-plan-type attribute.
   */
  get profilePlanType() {
    if (this.profilePlanFieldTarget.type === 'hidden') {
      return this.profilePlanTypeValue;
    }

    const profilePlanOption = this.profilePlanFieldTarget.selectedOptions[0];
    return profilePlanOption ? profilePlanOption.dataset.planType : null;
  }

  /**
   * Get the currently selected profile plan tier from the data-plan-tier attribute.
   */
  get profilePlanTier() {
    if (this.profilePlanFieldTarget.type === 'hidden') {
      return this.profilePlanTierValue;
    }

    const profilePlanOption = this.profilePlanFieldTarget.selectedOptions[0];
    return profilePlanOption ? profilePlanOption.dataset.tier : null;
  }

  /**
   * Get the currently selected profile title from the data-profile-title attribute.
   */
  get profileTitle() {
    const titleOption = this.titleFieldTarget.selectedOptions[0];
    return titleOption ? titleOption.dataset.profileTitle : null;
  }
}
