import { Controller } from '@hotwired/stimulus';

/**
 * directory-header controller. Trigger form submission from action.
 */
export default class extends Controller {
  static targets = ['formElement'];

  submitForm() {
    this.formElementTarget.submit();
  }
}
