// Parent class for all metric subclasses (Counter for now)
export default class Metric {
  constructor(name) {
    this.name = name;
    this.dimensions = [];
  }

  withDimension(dimension) {
    if (dimension && !this.dimensions.includes(dimension)) {
      this.dimensions.push(dimension);
    }

    return this;
  }

  withoutDimension(dimension) {
    const idx = this.dimensions.indexOf(dimension);

    if (idx > -1) {
      this.dimensions.splice(idx, 1);
    }

    return this;
  }
}
