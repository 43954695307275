import { Controller } from '@hotwired/stimulus';
import { publish, events } from 'lib/event_bus';

const idAttr = 'data-edit-box-id-value';
const controllerSelector = 'data-controller=edit-box';

/**
 * Remote forms that may be embedded in an object's show page,
 * allowing for editing without navigating to the object's edit page.
 */
export default class extends Controller {
  static targets = ['editingHidden', 'editingVisible', 'input'];

  static values = { id: String };

  edit(event) {
    this.editingHiddenTargets.forEach((target) => target.classList.add('hide'));
    this.editingVisibleTargets.forEach((target) =>
      target.classList.remove('hide'),
    );
    if (event) event.preventDefault();

    // If form contains a single text element, automatically move focus to it
    const textInputs = this.element.querySelectorAll(
      'textarea, input[type=text]',
    );
    if (textInputs.length === 1) textInputs[0].focus();
  }

  cancel(event) {
    this.editingHiddenTargets.forEach((target) =>
      target.classList.remove('hide'),
    );
    this.editingVisibleTargets.forEach((target) =>
      target.classList.add('hide'),
    );
    if (event) event.preventDefault();
  }

  update(event) {
    const html = event.detail[0];

    // Re-render the DOM using updated attributes.
    //
    // First, copy over the original controllers so users editing multiple
    // form groups at once don't lose their work.
    document
      .querySelectorAll(`[${controllerSelector}]`)
      .forEach((controller) => {
        const id = controller.dataset.editBoxIdValue;
        if (id === this.idValue) return;

        const selector = `[${controllerSelector}][${idAttr}=${id}]`;
        const newController = html.body.querySelector(selector);
        newController.parentNode.replaceChild(controller, newController);
      });

    document.title = html.title;
    document.body = html.body;
  }

  error(event) {
    const { status } = event.detail[2];

    // Alert user in event of any server-side errors
    if (status !== 422) {
      let title = 'Something went wrong';
      let content = 'Your profile could not be updated.';

      if (status === 413) {
        title =
          'The uploaded file(s) or request exceeds the maximum size allowed.';
        content =
          'Please upload files in smaller batches or try using smaller files.';
      }

      const params = {
        targetId: 'app-modal',
        title,
        content,
      };
      publish(events.MODAL_DISPLAY, params);
    }

    // Update DOM with validation error messages
    const html = event.detail[0].querySelector(`[${idAttr}=${this.idValue}]`);
    this.element.innerHTML = html.innerHTML;
    this.edit();
  }
}
