import { Controller } from '@hotwired/stimulus';

/**
 * secure--payments--checkout controller
 */
export default class extends Controller {
  static targets = ['amountWithFee', 'amountWithoutFee'];

  toggleFee(event) {
    this.amountWithFeeTarget.classList.toggle('show', event.target.checked);
    this.amountWithoutFeeTarget.classList.toggle('show', !event.target.checked);
  }
}
