import { Controller } from '@hotwired/stimulus';

/**
 * location-hash-preserver controller: store the current location hash (if present)
 * on the target input or appended to the target link.  Allows the hash fragment
 * to be preserved during redirects, e.g. through login.
 */
export default class extends Controller {
  static targets = ['input', 'link'];

  connect() {
    const { hash } = window.location;
    if (!hash) {
      return;
    }

    if (this.hasInputTarget) {
      this.inputTarget.value = hash;
    }

    if (this.hasLinkTarget) {
      this.linkTarget.href += hash;
    }
  }
}
