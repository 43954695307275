import { Controller } from '@hotwired/stimulus';

/**
 * Scroll controller.
 *
 * Control page scrolling.
 */
export default class extends Controller {
  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
