import { Controller } from '@hotwired/stimulus';
import Tooltip from 'bootstrap/js/dist/tooltip';

/**
 * tooltip controller.
 *
 * Configure Bootstrap tooltips.
 */
export default class extends Controller {
  static values = { html: Boolean, textAlignLeft: Boolean };

  connect() {
    this.createTooltip();

    // A bug in Bootstrap Tooltip causes an error to be thrown if the tooltip's
    // element has display:none when the tooltip is shown after a timeout delay:
    // https://github.com/twbs/bootstrap/issues/35191
    //
    // Fix by observing style changes to the tooltip element and destroying the
    // tooltip when the element is display:none.
    this.styleObserver = new MutationObserver((mutations) => {
      mutations.forEach((mut) => {
        if (mut.type === 'attributes' && mut.attributeName === 'style') {
          if (this.element.style.display === 'none') {
            this.tooltip?.dispose();
            this.tooltip = null;
          } else if (!this.tooltip) {
            this.createTooltip();
          }
        }
      });
    });

    this.styleObserver.observe(this.element, {
      attributes: true,
      attributeFilter: ['style'],
    });
  }

  disconnect() {
    this.tooltip?.dispose();
    this.tooltip = null;
  }

  createTooltip() {
    // boundary: 'window' fixes a bug that causes tooltips to flicker when inside
    // .table-responsive elements: https://github.com/twbs/bootstrap/issues/26836
    const options = { boundary: 'window' };

    if (this.hasHtmlValue) {
      options.html = this.htmlValue;
    }

    if (this.hasTextAlignLeftValue) {
      options.customClass = 'text-start';
    }

    this.tooltip = new Tooltip(this.element, options);
  }
}
