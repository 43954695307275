import { Controller } from '@hotwired/stimulus';
import Collapse from 'bootstrap/js/dist/collapse';

/**
 * highlight-fields controller: apply highlight css class to non-blank fields.
 *
 * On connect and when a field value changes, toggle the highlight-field class
 * on the field depending on whether it has a value (or is checked).
 *
 * The list of fields is identified through a query selector on [input, select]
 * instead of through stimulus targets to make it easy to apply this controller
 * to a form with a large number of fields.
 *
 * Targets:
 * - indicator: optional element(s) that will receive the highlight-field class
 *    if there are any set fields in the controller.
 * - collapse: optional Bootstrap collapse element(s) that will be shown if any child nodes
 *    below them have also received the highlight-field class.
 */
export default class extends Controller {
  static targets = ['collapse', 'indicator'];

  connect() {
    // track event listeners so that they may be removed at disconnect
    this.listeners = [];
    // track which fields have values
    this.setFields = new Set();

    this.element.querySelectorAll(this.fieldSelector).forEach((element) => {
      this.toggleHighlight(element);
      this.addListener(element);
    });
    this.openHighlightedCollapse();
  }

  disconnect() {
    this.listeners.forEach(([element, type, listener]) => {
      element.removeEventListener(type, listener);
    });
  }

  /**
   * Add a change listener to the element that toggles higlighting.
   */
  addListener(element) {
    const type = 'change';

    const listener = () => this.toggleHighlight(element);
    element.addEventListener(type, listener);
    this.listeners.push([element, type, listener]);
  }

  /**
   * Toggle the highlight-field class on the element depending on whether it has
   * a value (or is checked).  Also add or remove it from the setFields set,
   * and toggle any indicator targets.
   */
  toggleHighlight(element) {
    let isSet = !!element.value;

    if (element.type === 'checkbox') {
      isSet = element.checked;
    }

    element.classList.toggle(this.highlightFieldClass, isSet);

    if (isSet) {
      this.setFields.add(element);
    } else {
      this.setFields.delete(element);
    }

    this.indicatorTargets.forEach((indicator) => {
      indicator.classList.toggle(
        this.highlightFieldClass,
        this.setFields.size > 0,
      );
    });
  }

  /**
   * If any highlighted collapse indicators are toggleable Bootstrap collapse elements, open them.
   */
  openHighlightedCollapse() {
    const { highlightFieldClass } = this;
    this.collapseTargets.forEach((collapse) => {
      if (collapse.querySelector(`.${highlightFieldClass}`)) {
        Collapse.getOrCreateInstance(collapse, { toggle: false }).show();
      }
    });
  }

  get fieldSelector() {
    return 'input:not([type="submit"]), select, textarea';
  }

  get highlightFieldClass() {
    return 'highlight-field';
  }
}
