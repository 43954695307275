import { Controller } from '@hotwired/stimulus';

/**
 * ajax form controller: manage ajax form submission with serialized fields.
 *
 * Handle some basic functionality around submitting a form via an ajax request:
 * - calls $.ajax, and handles the response (e.g. by appending to the container
 *    or showing an error message)
 * - serializes dataInput targets
 *
 * This class can also be subclassed to handle more specific needs.  See:
 * - ajax-form--inline: the user can switch between a static display and a single
 *    form field to edit that value (similar to the best_in_place gem).
 * - ajax-form--fetched-inline: like ajax-form--inline, but the form html is
 *    fetched from the server and so can include more complex field sets.
 *
 * Targets:
 * - appendContainer: if present, the form submission response will be added to
 *    this target's innerHTML.  Note that this is a security risk.
 * - clearOnSuccess {element with value attr}: on success, these targets will
 *    have their values clear
 * - dataInput {element with value attr}: on submit, these targets will have
 *    their names and values serialized and submitted.  An alternative name
 *    for the data submission can be provided with the nameAttr.
 * - submitButton {button}: during the request, these targes will be disabled
 *
 * Values:
 * - method {string}: the HTTP method to use for the request (e.g. 'POST', 'GET',
 *    'PUT').  Default: 'POST'.
 * - url {string}: the URL to which the request is sent.  Required.
 */
export default class extends Controller {
  static targets = [
    'appendContainer',
    'clearOnSuccess',
    'dataInput',
    'submitButton',
  ];

  static values = {
    loadFormUrl: String,
    method: String,
    url: String,
  };

  /**
   * Submit the form via an ajax request with serialized data.
   */
  submitForm() {
    this.toggleSubmitting(true);

    $.ajax({
      ...this.submitOptions(),
      success: (response) => {
        this.handleSuccess(response);
      },
      error: (response, status, message) => {
        this.handleErrorResponse(response, message);
      },
    }).always(() => {
      this.toggleSubmitting(false);
    });
  }

  /**
   * Return the options for the ajax call to submit the form.
   */
  submitOptions() {
    return {
      method: this.methodValue || 'POST',
      url: this.urlValue,
      data: this.serialize(),
    };
  }

  /**
   * Update the form to show that it's submitting (or not).
   *
   * @param submitting {Boolean}
   */
  toggleSubmitting(submitting) {
    this.submitButtonTargets.forEach((button) => {
      button.disabled = submitting;
    });
  }

  /**
   * Serialize the data input targets into a hash of names and values.  If a
   * name is repeated, an array will be used to hold all the values.
   */
  serialize() {
    const data = {};
    this.dataInputTargets.forEach((dataInputTarget) => {
      const name =
        dataInputTarget.getAttribute(this.nameAttr) || dataInputTarget.name;
      const { value } = dataInputTarget;

      if (data[name] !== undefined) {
        if (!Array.isArray(data[name])) {
          data[name] = [data[name]];
        }
        data[name].push(value);
      } else {
        data[name] = value;
      }
    });

    return data;
  }

  /**
   * Show the successful form submission response.
   */
  handleSuccess(response) {
    if (this.hasAppendContainerTarget) {
      this.appendContainerTarget.innerHTML += response;
    }

    // Clear the form fields after success
    this.clearOnSuccessTargets.forEach((target) => {
      target.value = '';
      target.dispatchEvent(new Event('change'));
    });
  }

  /**
   * Show an error response to the user.
   *
   * @param response {jqXHR | Error} ideally, a jQuery response object (jqXHR)
   *    with a responseJSON object with a messages array of error strings,
   *    or just a responseText string.
   * @param fallbackMessage {String} message to be shown if the response object
   *    does not contain an error message.
   */
  handleErrorResponse(response, fallbackMessage) {
    let msg = response?.responseText || response?.statusText || fallbackMessage;

    if (response?.responseJSON?.messages?.length) {
      msg = response.responseJSON.messages.join('\n');
    }

    alert(msg); // eslint-disable-line no-alert
  }

  /**
   * Focus on the first non-hidden data input target.
   */
  focusFirstField() {
    const firstField = this.dataInputTargets.find(
      (field) => field.type !== 'hidden',
    );

    if (firstField) {
      firstField.focus({ preventScroll: true });
      if (firstField.selectionStart !== undefined) {
        firstField.selectionStart = firstField.value.length;
        firstField.selectionEnd = firstField.value.length;
      }
    }
  }

  /**
   * Return the name of this controller (subclasses most override this).
   */
  get controllerName() {
    return 'ajax-form';
  }

  /**
   * Return the DOM element attribute name used to change the serialized name
   * of a field.
   */
  get nameAttr() {
    return `data-${this.controllerName}-name-value`;
  }

  /**
   * Return the CSRF authentication token from a document meta tag.
   */
  get csrfToken() {
    return document.querySelector('meta[name=csrf-token]')?.content;
  }
}
