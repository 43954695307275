/**
 * Hash of all publishable event names.
 */
export const events = {
  DESTROYED: 'destroyed',
  GOOGLE_MAPS_CALLBACK: 'google_maps_callback', // see components/map/head/_head.html.haml
  MAP_CLICK_POLYGON: 'map_click_polygon',
  MAP_MOUSEOUT_POLYGON: 'map_mouseout_polygon',
  MAP_MOUSEOVER_POLYGON: 'map_mouseover_polygon',
  MAP_CLEAR_POLYGONS_SELECTED: 'map_clear_polygons_selected',
  MAP_TOGGLE_POLYGON_SELECTED: 'map_toggle_polygon_selected',
  MODAL_DISPLAY: 'modal_display',
  REMOVED: 'removed',
};

/**
 * Hash of active subscriptions, by subscriber.
 */
const subscriptions = {};

// Returns true if the given eventName exists in the publishable events hash.
const isEvent = (eventName) => {
  return Object.values(events).includes(eventName);
};

/**
 * Publishes a custom event on the document with the given eventName.
 *
 * @param {string} eventName The event name to subscribe to.  Required, and must
 *    exist in the events hash.
 * @param {object} detail The detail object to pass with the event.  Optional.
 * @param {element} on The element on which to dispatch the event.
 *    Default: document.
 * @param {boolean} bubbles If true, the event will bubble up from this element.
 *    Default: true.
 * @param {boolean} cancelable If true, the event can be canceled by a listener.
 *    Default: true.
 */
export const publish = (
  eventName,
  detail = {},
  { on, bubbles = true, cancelable = true } = {},
) => {
  if (!isEvent(eventName)) {
    throw new Error(`Cannot publish to undefined event ${eventName}`);
  }

  const event = new CustomEvent(eventName, {
    bubbles,
    cancelable,
    detail,
  });

  (on || document).dispatchEvent(event);
};

/**
 * Subscribes the given callback to the given eventName.  Listens for the event
 * on the document.
 *
 * @param {string} eventName The event name to subscribe to.  Required, and must
 *    exist in the events hash.
 * @param {function} callback Function call when event is triggered.  Required.
 * @param {object} subscriber The subscription subscriber.  If present,
 *    the subscription's unsubscribe method will be registered under the
 *    subscriber and all the subscriber's unsubscribe methods can be called
 *    from unsubscribe().  Optional.
 * @returns {function} unsubscribe function
 */
export const subscribe = (eventName, callback, { subscriber }) => {
  if (!isEvent(eventName)) {
    throw new Error(`Cannot subscribe to undefined event ${eventName}`);
  }
  if (!callback) {
    throw new Error(
      `Cannot subscribe to ${eventName} with an undefined callback`,
    );
  }

  document.addEventListener(eventName, callback);

  const unsub = () => document.removeEventListener(eventName, callback);

  if (subscriber) {
    if (!subscriptions[subscriber]) {
      subscriptions[subscriber] = [];
    }
    subscriptions[subscriber].push(unsub);
  }

  return unsub;
};

/**
 * Unsubscribe all the registered subscriptions for the given subscriber.
 */
export const unsubscribe = (subscriber) => {
  if (subscriptions[subscriber]) {
    subscriptions[subscriber].forEach((unsub) => unsub());
    delete subscriptions[subscriber];
  }
};
