import Metric from './metric';

/**
 * Represents all data collected by CSM on a page. For now it
 * just contains counters, but can be extended to timings, errors
 * and other clientside events we may be interested in.
 */
export default class MetricData {
  constructor() {
    this.metrics = [];
  }

  // Returns true if there is no clientside metrics to publish
  isEmpty() {
    return this.metrics.length === 0;
  }

  // Adds a metric object to be published later.
  addMetric(metric) {
    if (!(metric instanceof Metric)) {
      throw new Error('Object is not a Metric instance');
    }

    if (this.metrics.includes(metric)) {
      return metric;
    }

    this.metrics.push(metric);

    return metric;
  }

  // Deletes a metric object from being published later
  deleteMetric(metric) {
    const idx = this.metrics.indexOf(metric);

    if (idx > -1) {
      this.metrics.splice(idx, 1);
    }
  }

  clone() {
    return $.extend(true, new MetricData(), this);
  }
}
