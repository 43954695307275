/**
 * Display activity spinner.
 */

import { renderTemplate } from 'lib/render';
import spinnerTemplate from './spinner.html.eta';
import spinnerButtonTemplate from './spinner_button.html.eta';
import spinnerOverlayTemplate from './spinner_overlay.html.eta';

/**
 * Add a spinner element to the given element.
 *
 * @param {Element} el Element to add spinner to
 * @option {String} type:
 *  - button: a small spinner is appended, and the button is temporarily disabled.
 *  - overlay: a semi-opaque overlay is shown across the el, and the element
 *    position is changed temporarily to relative.
 */
export const startSpinner = (el, options = {}) => {
  if (!el) {
    return;
  }

  const $el = $(el);
  const data = {};

  if (options.type === 'button') {
    data.el = $(renderTemplate(spinnerButtonTemplate));
    $el.append(data.el);

    data.disabled = false;
    $el.attr('disabled', true);
  } else if (options.type === 'overlay') {
    data.el = $(renderTemplate(spinnerOverlayTemplate));
    $el.append(data.el);

    data.position = $el.css('position');
    $el.css('position', 'relative');
  } else {
    data.el = $(renderTemplate(spinnerTemplate));
    $el.append(data.el);
  }

  $el.data('spinner', data);
};

/**
 * Remove a spinner element from the given element.
 * @param {Element} el Element to remove spinner from
 */
export const stopSpinner = (el) => {
  const $el = $(el);
  const data = $el.data('spinner');

  if (!data) {
    return;
  }

  if (data.el) {
    $(data.el).remove();
  }

  if (data.disabled != null) {
    $el.attr('disabled', data.disabled);
  }

  if (data.position != null) {
    $el.css('position', data.position);
  }

  $el.data('spinner', undefined);
};

/**
 * Toggle appearance of spinner.
 */
export const spinnerToggle = (el, show, options = {}) => {
  if (show) {
    startSpinner(el, options);
  } else {
    stopSpinner(el);
  }
};
