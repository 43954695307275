import { Controller } from '@hotwired/stimulus';
import html2canvas from 'html2canvas';
import * as clipboard from 'clipboard-polyfill';

/**
 * copy-to-clipboard controller.
 *
 * Provide ability to click and copy contents to clipboard
 */
export default class extends Controller {
  static targets = ['content'];

  static values = { text: String };

  /**
   * Copy the text value or the content target HTML to the clipboard.
   */
  copyToClipboard(event) {
    const control = event.target;
    const text = this.hasTextValue
      ? this.textValue
      : this.contentTarget.innerHTML;

    const item = new clipboard.ClipboardItem({
      'text/html': text,
      'text/plain': text,
    });

    clipboard
      .write([item])
      .then(() => {
        this.showAlertTooltip($(control), 'Copied!');
      })
      .catch(() => {
        this.showAlertTooltip($(control), 'Copy failed');
      });
  }

  /**
   * Copy a PNG screenshot (using html2canvas) of the content target to the clipboard.
   *
   * If the screenshotted element contains images, make sure they are either
   * a) served from the same origin, or
   * b) served with CORS allow-origin headers that allow for the image to be loaded in the canvas.
   *    For example, we serve our FPF premium ad images from a cloudwatch CDN, which has
   *    been configured to add the appropriate response headers. Further info:
   *    - https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/creating-response-headers-policies.html
   *    - https://us-east-1.console.aws.amazon.com/cloudfront/v3/home?region=us-east-1&skipRegion=true#/policies/responseHeaders/63be904c-3ac7-4b58-955f-019135d2d28f
   *
   * This is currently only supported by Chrome and Safari:
   * https://caniuse.com/mdn-api_clipboarditem
   */
  copyScreenshotToClipboard(event) {
    const control = event.target;

    html2canvas(this.contentTarget, {
      allowTaint: false, // allow for images to be captured in the screenshot as well
      useCORS: true,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
      onclone: (document, el) => {
        // give the element a little extra padding
        el.classList.add('p-1');
      },
    }).then((canvas) => {
      canvas.toBlob((blob) => {
        // clipboard-polyfill fails in this case without a clear error message,
        // so use the native API instead
        // eslint-disable-next-line no-undef
        const item = new ClipboardItem({ [blob.type]: blob });

        navigator.clipboard
          .write([item])
          .then(() => {
            this.showAlertTooltip($(control), 'Copied!');
          })
          .catch(() => {
            this.showAlertTooltip($(control), 'Copy failed', { timeout: 3000 });
          });
      });
    });
  }

  showAlertTooltip($el, content, { timeout = 1500 } = {}) {
    $el
      .tooltip('dispose')
      .tooltip({
        title: content,
        placement: 'right',
        trigger: 'manual',
      })
      .tooltip('show');

    setTimeout(() => {
      $el.tooltip('dispose');
    }, timeout);
  }
}
