import MetricsController from './metrics_controller';

/**
 * Parent class for form controllers that collect metrics about a form,
 * such as abandonments, history, time a user spent on each field etc
 */
export default class extends MetricsController {
  // Returns the form the controller is bound for or raises an error
  // if no or multiple forms have been found
  //
  // @returns {jQuery} the form element found in the element the controller
  //   is connected to
  getForm() {
    const forms = $(this.element).find('form') || [];

    if (forms.length === 0) {
      throw new Error('No form found for metrics controller');
    }

    return $(forms[0]);
  }

  // Returns the form input elements a user can interact with
  // @returns {jQuery} the collection of elements within the form
  //  that the user can interact with (select, input, textarea)
  getFormElements() {
    return $(this.element).find('select, input, textarea');
  }
}
