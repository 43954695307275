import { Controller } from '@hotwired/stimulus';

/**
 * profile-area-fields controller: update areas select options when profile changes.
 */
export default class extends Controller {
  static targets = ['areasContainer', 'areasField', 'profileField'];

  static values = {
    areas: Object,
    profilesDefaultPostingAreaIds: Object,
  };

  connect() {
    this.changeProfile();
  }

  /**
   * When the selected profile is changed, get the areas that match the profile
   * and create new area options for them.
   */
  changeProfile() {
    const regions = this.areasValue[this.selectedProfileId] || [];

    // Remove all the current options
    this.areasFieldTarget
      .querySelectorAll('option')
      .forEach((option) => option.remove());
    this.areasFieldTarget
      .querySelectorAll('optgroup')
      .forEach((optgroup) => optgroup.remove());

    // Create new options based on the areas available for the current profile.
    regions.forEach((areaGroup) => {
      const optgroup = document.createElement('optgroup');
      optgroup.setAttribute('label', areaGroup.region_name);
      this.areasFieldTarget.appendChild(optgroup);

      areaGroup.areas.forEach((area) => {
        const option = new Option(area.name, area.id);
        optgroup.appendChild(option);
      });
    });

    const selectedAreaIds =
      this.profilesDefaultPostingAreaIdsValue[this.selectedProfileId] || [];
    $(this.areasFieldTarget).val(selectedAreaIds);
    this.areasFieldTarget.dispatchEvent(new Event('change'));

    // Show the areas field only if it contains multiple options
    this.areasContainerTarget.classList.toggle(
      'show',
      this.areasFieldTarget.options.length > 1,
    );
  }

  get areas() {
    return Object.values(this.areasValue)
      .flat()
      .map((r) => r.areas)
      .flat();
  }

  get selectedProfileId() {
    const id = this.profileFieldTargets.find((input) => input.checked)?.value;
    return id ? parseInt(id, 10) : undefined;
  }

  get selectedAreaIds() {
    return Array.from(this.areasFieldTarget.selectedOptions).map((option) =>
      parseInt(option.value, 10),
    );
  }

  get selectedAreas() {
    const areaIds = this.selectedAreaIds;
    return this.areas.filter((area) => areaIds.includes(area.id));
  }
}
