import { Controller } from '@hotwired/stimulus';

/**
 * replace controller: replace the element's inner html with the xhr response.
 */
export default class extends Controller {
  replace(event) {
    const [_data, _status, xhr] = event.detail;
    this.element.innerHTML = xhr.response;
  }
}
