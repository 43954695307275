import { Controller } from '@hotwired/stimulus';
import { subscribe, unsubscribe, events } from 'lib/event_bus';

/**
 * calendar--event--location-fields controller: location fields logic.
 */
export default class extends Controller {
  static targets = ['addressInput'];

  static values = {};

  connect() {
    // If google maps hasn't yet loaded
    if (this.google) {
      this.initializeAddressAutocompletion();
    } else {
      subscribe(
        events.GOOGLE_MAPS_CALLBACK,
        this.initializeAddressAutocompletion.bind(this),
        {
          subscriber: this,
        },
      );
    }
  }

  disconnect() {
    unsubscribe(this);
  }

  initializeAddressAutocompletion() {
    // Rectangle covering a point NE of VT to SW of MA and our NY towns
    const defaultBounds = {
      north: 45.341845,
      south: 42.020623,
      east: -71.256091,
      west: -75.094151,
    };

    const options = {
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'geometry', 'name'],
      bounds: defaultBounds,
      strictBounds: false, // allow places outside of the default bounds
    };

    /* eslint-disable no-new */
    new this.google.maps.places.Autocomplete(this.addressInputTarget, options);
    /* eslint-enable no-new */

    // If the places dropdown is visible, disable the enter key event on the
    // location input so that it doesn't submit the form (since it's more natural
    // for enter to select the current item in a dropdown)
    this.addressInputTarget.addEventListener('keydown', (e) => {
      if (
        e.keyCode === 13 &&
        document.querySelector('.pac-container .pac-item')
      ) {
        e.preventDefault();
      }
    });
  }

  get google() {
    return window.google;
  }
}
