/**
 * Perform an Application Visit to the given location (a string containing a URL
 * or path) with the specified action (a string, either "advance" or "replace").
 *
 * By default, updates window.location, but alternate behavior (e.g. to use
 * Turbo.visit) can be injected with the injectVisit method.
 */
/* eslint-disable-next-line import/no-mutable-exports */
export let visit = (location, { action = 'advance' } = {}) => {
  if (action === 'replace') {
    window.location.replace(location);
  } else {
    window.location = location;
  }
};

/**
 * Inject an alternative method for visiting a window location, e.g. Turbo.visit.
 */
export function injectVisit(visitFn) {
  visit = visitFn;
}
