import mailcheck from 'mailcheck';

import ValidateFieldController from '../field_controller';
import domains from './domains';

/**
 * mailcheck controller: show suggestions for common email domain typos.
 */
export default class extends ValidateFieldController {
  connect() {
    this.showValidationOptions = {
      ...this.showValidationOptions,
      state: 'warning',
      html: true,
    };
  }

  validate() {
    mailcheck.run({
      email: this.fieldElement.value,
      domains,
      suggested: (suggestion) => {
        this.suggest(suggestion);
      },
      empty: () => {
        this.clearValidation();
      },
    });
  }

  suggest(suggestion) {
    this.suggestion = suggestion;
    this.showValidation();
  }

  useSuggestion(event) {
    event.preventDefault();

    this.fieldElement.value = this.suggestion.full;
    this.clearValidation();
  }

  get message() {
    if (!this.suggestion) {
      return null;
    }

    return (
      `Did you mean <a href="#" class="fw-bold text-decoration-underline" ` +
      `data-action="click->validate--mailcheck#useSuggestion">${this.suggestion.full}</a>?`
    );
  }
}
