import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

/**
 * payments--form controller: submit a credit card to Stripe.
 *
 * Submits credit card data to Stripe via the payments--credit-card controller
 * before submitting the form to FPF.
 *
 * If there's a payment selection controller child, first checks that a new
 * source is selected before trying to submit to stripe.  If there's not,
 * the submit event proceeds without interference.
 */
export default class extends Controller {
  submit(event) {
    event.preventDefault();
    event.stopPropagation();

    Rails.disableElement(this.element);

    if (this.newSourceSelected) {
      this.creditCardController
        .createToken()
        .then(() => {
          // Note: form will be re-enabled automatically after submit completes
          this.element.submit();
        })
        .catch(() => {
          Rails.enableElement(this.element);
        });
    } else if (this.existingSourceSelected) {
      this.paymentSelectionController
        .createToken()
        .then(() => {
          // Note: form will be re-enabled automatically after submit completes
          this.element.submit();
        })
        .catch(() => {
          Rails.enableElement(this.element);
        });
    }
  }

  get newSourceSelected() {
    return (
      !this.paymentSelectionController ||
      this.paymentSelectionController.newSourceSelected
    );
  }

  get existingSourceSelected() {
    return (
      this.paymentSelectionController &&
      this.paymentSelectionController.existingSourceSelected
    );
  }

  get paymentSelectionController() {
    const name = 'payments--payment-selection';
    return this.application.getControllerForElementAndIdentifier(
      this.element.querySelector(`[data-controller="${name}"]`),
      name,
    );
  }

  get creditCardController() {
    const name = 'payments--credit-card';
    return this.application.getControllerForElementAndIdentifier(
      this.element.querySelector(`[data-controller="${name}"]`),
      name,
    );
  }
}
