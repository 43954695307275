import Metric from './metric';

export default class Counter extends Metric {
  value = 0;

  constructor(counterName, initialValue = 1) {
    super(counterName);
    this.value = initialValue;
  }

  adjust(by = 1) {
    this.value += by;
  }
}
