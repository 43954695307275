import MatchController from '../../search/match_controller';

import matchResultsTemplate from './match_results.html.eta';

/**
 * profiles--match controller: find profiles matching current form fields.
 */
export default class extends MatchController {
  static values = { areaIds: String };

  requestData(value) {
    return {
      name: value,
      exclude_id: this.excludeIdValue,
      area_ids: this.areaIdsValue,
    };
  }

  resultsFromResponse(response) {
    return response.profiles || [];
  }

  get matchResultsTemplate() {
    return matchResultsTemplate;
  }
}
