import MatchController from '../../../search/match_controller';

import matchResultsTemplate from './match_results.html.eta';

/**
 * events--match controller: find profiles matching current form fields.
 */
export default class extends MatchController {
  static targets = [
    'startDateInput',
    'endDateInput',
    'areaIdsSelect',
    'eventIdInput',
  ];

  get requestType() {
    return 'POST';
  }

  requestData(value) {
    this.previousSelectedEventId = parseInt(
      this.eventIdInputTargets.find((el) => el.checked)?.value,
      10,
    );

    return {
      query: value,
      start_date: this.startDateInputTarget.value,
      end_date: this.endDateInputTarget.value,
      area_ids: Array.from(this.areaIdsSelectTarget.selectedOptions).map(
        (o) => o.value,
      ),
    };
  }

  resultsFromResponse(response) {
    return response || [];
  }

  dataFromResponse(response) {
    const selectedEventId =
      response
        .map((el) => el.id)
        .find((id) => id === this.previousSelectedEventId) || '';

    return { selectedEventId };
  }

  get matchResultsTemplate() {
    return matchResultsTemplate;
  }
}
