import Select2Controller from './select2_controller';

/**
 * ajax-select2 controller: use select2 to search for remote data.
 *
 * Adds additional config and processing to the Select2Controller parent class
 * via the select2 ajax config (https://select2.org/data-sources/ajax) in order
 * to load select options from a remote data source via AJAX requests.
 *
 * This is meant to be subclassed by model-specific versions (e.g. a subclass
 * that searches on users).  Subclasses should:
 * - assign values to the `url` and `queryTerm` static class members
 * - override the `processResults` method
 */
export default class extends Select2Controller {
  static values = { ...super.values, params: Object };

  static url = ''; // subclass should specify remote URL

  static queryTerm = ''; // subclass should specify request param name for search term

  static idQueryTerm = 'q[id_eq]';

  static config = {
    ...super.config,
    minimumInputLength: 1,
    ajax: {
      dataType: 'json',
      delay: 250,
    },
  };

  initialize() {
    super.initialize();

    this.config.ajax = { ...this.config.ajax };
    this.config.ajax.url = this.url;
    this.config.ajax.data = this.ajaxData.bind(this);
    this.config.ajax.processResults = this.processResults.bind(this);
  }

  /**
   * Returns params for the AJAX search request, including:
   * - pagination data
   * - search term param
   * - additional params provided through the params controller value
   */
  ajaxData(params) {
    // If searching on a number, assume it's an id and match on id equality.
    // Otherwise, use the query term provided by the subclass.
    const queryTerm = /^\d+$/.test(params.term)
      ? this.idQueryTerm
      : this.queryTerm;

    return {
      per_page: 50,
      [queryTerm]: params.term,
      ...this.paramsValue,
    };
  }

  /**
   * Process the API request results and return an object like:
   *
   * {
   *   results: [
   *     { id: 1, text: 'foo' }
   *   ]
   * }
   *
   * For more, see https://select2.org/data-sources/ajax#transforming-response-data
   */
  processResults(data) {
    return data;
  }

  get url() {
    return this.constructor.url;
  }

  get queryTerm() {
    return this.constructor.queryTerm;
  }

  get idQueryTerm() {
    return this.constructor.idQueryTerm;
  }
}
