import AjaxFormController from './ajax_form_controller';
import simpleFormat from '../../lib/simple_format';

/**
 * ajax-form--inline controller: manage a form that temporarily replaces a static value.
 *
 * Allows a static view to be temporarily converted into a form to edit the
 * static data, and handles form submission and updating the static view with
 * the response from the server.
 *
 * Currently supports a single <input> or <textarea>, but this code can be
 * expanded if other simple fields are needed.
 *
 * Editing mode is entered by clicking the display value.  The modified value is
 * saved on blur.  Pressing escape exits editing mode without saving a value.
 *
 * Loosely based on:
 * - https://github.com/eelcoj/stimulus-inline-edit
 * - https://github.com/bernat/best_in_place
 *
 * Values:
 * - model {string}: the name of the model class being edited; see also field.
 * - field {string}: the name of the model attribute being edited; used to construct
 *    the field name, element id, and to extract the value from the response JSON.
 * - value {string}: current value of the model attribute; used for the field
 *    value and to render the value to HTML when not in editing mode.
 * - as {string}: determines what field element is rendered for editing; currently
 *    supports "input" and "textarea".
 * - htmlAttrs {object}: hash of html arguments such as maxlength, default-value,
 *    etc. that will be set on the rendered field.
 * - displayWith {string}: identifies a helper method for converting the value
 *    to display HTML; currently supports "simple_format".
 * - placeholder {string}: the default text displayed when there's no value;
 *    without this, there would be nothing to click on to enter edit mode when
 *    the model field is blank.
 * - class {string}: additional classes to apply to the display element.
 * - showButtons {boolean}: control showing save/cancel buttons to submit form; default false
 * - displayTagName {string}: wrap form in this HTML tag; uses 'span' by default
 */
export default class extends AjaxFormController {
  static values = {
    ...AjaxFormController.values,
    model: String,
    field: String,
    value: String,
    as: String,
    htmlAttrs: Object,
    displayWith: String,
    placeholder: String,
    class: String,
    showButtons: Boolean,
    displayTagName: String,
  };

  connect() {
    super.connect();
    // Set initial display value
    this.element.innerHTML = this.displayHtml;
  }

  valueValueChanged() {
    this.element.innerHTML = this.displayHtml;
  }

  /**
   * Start editing the form.
   *
   * Sets the form html as the inner HTML of the element
   */
  startEditing() {
    if (this.editing) {
      return;
    }

    this.editing = true;

    this.element.innerHTML = this.formHtml;
    this.focusFirstField();
  }

  /**
   * Stop editing the form and replace the element HTML with the display value html.
   */
  stopEditing(event) {
    if (!this.editing) {
      return;
    }

    this.editing = false;

    this.element.innerHTML = this.displayHtml;
    event.stopImmediatePropagation();
  }

  keyupHandler(event) {
    // esc key cancels editing
    if (event.key === 'Escape' || event.key === 'Esc') {
      if (this.editing) {
        this.stopEditing();
        event.stopImmediatePropagation();
      }
    }
  }

  blurHandler() {
    // blur submits form
    if (this.editing) {
      this.submitForm();
    }
  }

  submitOptions() {
    return {
      ...super.submitOptions(),
      dataType: 'json',
    };
  }

  /**
   * Handle successful form submission response by displaying the value and
   * exiting edit mode.
   *
   * This expects response to be an object (from a JSON response) with a property
   * that matches the model name, containing a property that matches the field name,
   * e.g. { profile: { name: 'some name' } }
   */
  handleSuccess(response) {
    this.valueValue = response[this.modelValue][this.fieldValue] || '';
    this.stopEditing();

    super.handleSuccess(response);
  }

  /**
   * Return html for the display value.
   */
  get displayHtml() {
    let displayHtml = this.valueValue;

    // If there's no value to display, show the placeholder (or "-") so the user
    // has something to click on to enter edit mode.
    if (!displayHtml) {
      displayHtml = this.placeholderValue || '-';
    }

    // Handle value formatting.
    if (this.displayWithValue === 'simple_format') {
      displayHtml = simpleFormat(displayHtml);
    }

    // Handle custom tag type to display
    const tagType = this.displayTagNameValue
      ? this.displayTagNameValue
      : 'span';

    return `<${tagType} class="${this.classValue}">${displayHtml}</${tagType}>`;
  }

  /**
   * Return form html with a field for editing the value.
   */
  get formHtml() {
    return `
      <form
        action="${this.urlValue}"
        method="${this.methodValue || 'POST'}"
        accept-charset="UTF-8"
        data-remote="true"
      >
        <input name="utf8" type="hidden" value="✓" data-ajax-form--inline-target="dataInput">
        <input name="_method" type="hidden" value="patch" data-ajax-form--inline-target="dataInput">
        <input name="authenticity_token" type="hidden" value="${
          this.csrfToken
        }" data-ajax-form--inline-target="dataInput">
        ${this.fieldHtml}
        ${this.buttonHtml}
      </form>
    `;
  }

  /**
   * Return field html for editing the value.
   */
  get fieldHtml() {
    const value = this.valueValue || '';

    const htmlAttrs = Object.entries(this.htmlAttrsValue)
      .map(([k, v]) => `${k}="${v}"`)
      .join(' ');
    const submitOnBlur = this.showButtonsValue
      ? ''
      : 'blur->ajax-form--inline#blurHandler';
    const fieldAttrs = `
      name="${this.nameValue}"
      id="${this.idValue}"
      value="${this.valueValue}"
      class="form-control"
      data-action="${submitOnBlur} keyup->ajax-form--inline#keyupHandler"
      data-ajax-form--inline-target="dataInput"
      ${htmlAttrs}
    `;

    if (!this.asValue || this.asValue === 'input') {
      return `<input ${fieldAttrs} type="text" value="${value}">`;
    }

    if (this.asValue === 'textarea') {
      return `<textarea ${fieldAttrs}>${value}</textarea>`;
    }

    // LATER: additional input types can be supported as needed, and may require
    // additional values (e.g. an options list for a select field).
    throw new Error(`Unknown as option: ${this.asValue}`);
  }

  get buttonHtml() {
    if (!this.showButtonsValue) {
      return '';
    }

    return `
      <button
        type="button"
        class="btn btn-light"
        data-action="click->ajax-form--inline#submitForm">
          Save
      </button>
      <button
        type="button"
        class="btn btn-light"
        data-action="click->ajax-form--inline#stopEditing">
          Cancel
      </button>
    `;
  }

  get idValue() {
    return `${this.modelValue}_${this.fieldValue}`;
  }

  get nameValue() {
    return `${this.modelValue}[${this.fieldValue}]`;
  }

  get controllerName() {
    return 'ajax-form--fetched';
  }
}
