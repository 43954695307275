import StripeController from '../stripe/stripe_controller';

/**
 * payments--credit-card controller: stripe integration for saving payment sources.
 */
export default class extends StripeController {
  static targets = [
    'cvcContainer',
    'emailField',
    'errorContainer',
    'expiryContainer',
    'nameField',
    'numberContainer',
    'stripeTokenField',
  ];

  connect() {
    super.connect();
    this.initializeStripeElements();
  }

  initializeStripeElements() {
    // Configure credit card inputs, which are created and controlled by Stripe
    if (this.hasNumberContainerTarget) {
      const stripeElements = this.stripe.elements();

      this.cardNumber = stripeElements.create('cardNumber');
      const cardCvc = stripeElements.create('cardCvc');
      const cardExpiry = stripeElements.create('cardExpiry');

      this.cardNumber.mount(this.numberContainerTarget);
      cardCvc.mount(this.cvcContainerTarget);
      cardExpiry.mount(this.expiryContainerTarget);
    }

    this.element.classList.add('show');
  }

  createToken() {
    const errors = [];

    if (this.hasNameFieldTarget && !this.nameFieldTarget.value.trim()) {
      errors.push('Please fill out your first and last name.');
    }

    if (this.hasEmailFieldTarget && !this.emailFieldTarget.value.trim()) {
      errors.push('Please fill out your email.');
    }

    if (errors.length) {
      super.toggleError(errors.join(' '));
      return Promise.reject();
    }

    super.toggleFieldsDisabled(true);
    super.toggleError(false);

    // Use Stripe.js to create a token. We only need to pass in one Element
    // from the Element group in order to create a token.
    return this.stripe.createToken(this.cardNumber).then(
      (result) => {
        if (result.error) {
          super.toggleError(result.error.message || 'An error occurred.');
          super.toggleFieldsDisabled(false);
          return Promise.reject();
        }

        // Store the stripe token in a hidden form field.  The containing form
        // should now be submitted.
        this.stripeTokenFieldTarget.value = result.token.id;
        return Promise.resolve();
      },
      () => {
        super.toggleError('An error occurred.');
        super.toggleFieldsDisabled(false);
        return Promise.reject();
      },
    );
  }
}
