import { Controller } from '@hotwired/stimulus';

import FieldValidation from './field_validation';

/**
 * validate--field controller: show and clear validations on fields.
 *
 * This controller may be used on a form field or may contain a `field` target.
 */
export default class extends Controller {
  static targets = ['field'];

  initialize() {
    // Default options passed to FieldValidation.
    this.showValidationOptions = {
      state: 'invalid',
    };
  }

  disconnect() {
    this.clearValidation();
  }

  /**
   * Show validation on the field.
   */
  showValidation() {
    new FieldValidation(this.fieldElement).show(
      this.message,
      this.showValidationOptions,
    );
  }

  /**
   * Clear validation from the field.
   */
  clearValidation() {
    new FieldValidation(this.fieldElement).clear();
  }

  /**
   * Validate the field.  Subclass responsibility.
   */
  validate() {}

  /**
   * Return a validation message.  Subclass responsibility.
   */
  get message() {
    return null;
  }

  get fieldElement() {
    return this.hasFieldTarget ? this.fieldTarget : this.element;
  }
}
