// This controller provides common error handling for rails ajax requests and displays a modal
// dialog with the error message from the request. Called via a stimulus action:
//  ajax:error->rails-ajax#error
import { Controller } from '@hotwired/stimulus';
import { publish, events } from 'lib/event_bus';

export default class extends Controller {
  error(response) {
    const [details, status, message] = response.detail;
    let msg = details.responseText || message;

    if (details && details.messages) {
      msg = details.messages.join('\n');
    }

    const params = {
      targetId: 'app-modal',
      title: status,
      content: msg,
    };
    publish(events.MODAL_DISPLAY, params);
  }
}
