/* eslint-disable import/prefer-default-export */

import 'select2';

import { renderTemplate } from 'lib/render';
import selectAllButtonsTemplate from './select_all_buttons.html.eta';

const Select2Utils = $.fn.select2.amd.require('select2/utils');

/**
 * Handle optgroup click in select2.
 *
 * When an "optgroup" (which select2 renders as some other element) is clicked,
 * either select or deselect all options within that group.
 *
 * Note that this uses several internal elements of select2:
 * - accesses the select2 object from the jquery data, and triggers "open" on it
 * - select2/utils#GetData to get select2's internal data for an "optgroup" element
 * - the original "element" property of the "optgroup" element
 *
 * Note also that this is a hack.  It might be better to use or write a plugin like
 * https://github.com/bnjmnhndrsn/select2-optgroup-select, but that library
 * is broken on more recent versions of select2.
 */
function onSelect2ResultsGroupClick() {
  // Get select2's internal data from the group container, which is the parent
  // of the clicked element.  This data includes a link to the original optgroup
  // element.
  const $groupContainer = $(this).parent();
  const data = Select2Utils.GetData($groupContainer[0], 'data');
  const optgroup = data.element;
  const $select = $(optgroup.parentElement);

  // Get the values from the grouped options and the currently selected values
  const childrenIds = Array.from(optgroup.children).map(
    (option) => option.value,
  );
  const currentIds = $select.val();
  const allSelected = childrenIds.every((id) => currentIds.indexOf(id) > -1);

  let ids;
  if (allSelected) {
    ids = currentIds.filter((id) => !childrenIds.includes(id));
  } else {
    ids = currentIds.concat(childrenIds);
  }
  $select.val(ids);

  $select.trigger('change');

  // Trigger select or unselect on the select element because select2's change
  // listener isn't triggering any events that we can listen to in the select2
  // controller.
  $select
    .data('select2')
    .$element.trigger(ids.length ? 'select2:select' : 'select2:unselect');

  // Trigger 'open' on the select2 object to force it to set appropriate classes
  // on the dropdown items; it doesn't do this in response to the 'change' event.
  $select.data('select2').trigger('open');
}

/**
 * Multiselect dropdown adapter plugin for select2.
 *
 * - Renders "select all" and "deselect all" buttons at the top of the dropdown.
 * - Attaches click handlers to optgroup representations that select or deselect
 *    all contained options
 *
 * https://github.com/select2/select2/issues/195#issuecomment-367406889
 * https://stackoverflow.com/questions/47986002/select2-onselect-an-option-will-select-all-other-option
 */
$.fn.select2.amd.define(
  'select2/dropdown/multiselect',
  ['select2/utils', 'select2/dropdown', 'select2/dropdown/attachBody'],
  function multiselect(Utils, Dropdown, AttachBody) {
    function Multiselect() {}

    Multiselect.prototype.render = function render(decorated) {
      const $rendered = decorated.call(this);
      const self = this;

      if (!this.$element.prop('multiple')) {
        // this isn't a multi-select -> don't add the buttons!
        return $rendered;
      }

      const selectAllButtons = $(renderTemplate(selectAllButtonsTemplate));
      $rendered
        .find('.select2-dropdown')
        .addClass('select2-dropdown--multiple')
        .prepend(selectAllButtons);

      // LATER: this event handler needs to be removed when select2 is destroyed
      selectAllButtons.find('[data-action=selectAll]').on('click', () => {
        // Get all values from enabled select element options
        const values = $.map(self.$element.find('option:enabled'), (option) => {
          return option.value;
        });

        // Trigger value changed with all values
        self.$element.val(values);
        self.trigger('close');

        const event = new CustomEvent('change', {
          bubbles: true,
          cancelable: true,
        });
        self.$element.get(0).dispatchEvent(event);
      });

      // LATER: this event handler needs to be removed when select2 is destroyed
      selectAllButtons.find('[data-action=selectNone]').on('click', () => {
        // Trigger value changed with null value
        self.$element.val(null);
        self.trigger('close');

        const event = new CustomEvent('change', {
          bubbles: true,
          cancelable: true,
        });
        self.$element.get(0).dispatchEvent(event);
      });

      // LATER: this event handler needs to be removed when select2 is destroyed
      $rendered.on(
        'click',
        '.select2-results__group',
        onSelect2ResultsGroupClick,
      );

      return $rendered;
    };

    let dropdown = Utils.Decorate(Dropdown, AttachBody);
    dropdown = Utils.Decorate(dropdown, Multiselect);

    return dropdown;
  },
);

/**
 * Show selected count monkey patch for select2.
 *
 * Instead of rendering each selected tag, render a "select x of n" message if
 * the number of selected items is greater than the maximumSelectionLengthVisible
 * option.
 *
 * This is a monkey patch because it's not clear how to recreate all the
 * functionality that select2 applies, e.g. using SingleSelection or
 * MultipleSelection depending on the "multiple" attribute and decorating
 * MultipleSelection with various plugins depending on options.
 */
const MultipleSelection = $.fn.select2.amd.require(
  'select2/selection/multiple',
);
const oldUpdate = MultipleSelection.prototype.update;
MultipleSelection.prototype.update = function update(data) {
  const max = this.options.get('maximumSelectionLengthVisible');

  if (max !== null && max < data.length) {
    this.clear();
    const optionsCount = this.$element.find('option').length;

    const $rendered = this.$selection.find('.select2-selection__rendered');
    $rendered.append(
      '<li class="select2-selection__choice">' +
        `Selected ${data.length} out of ${optionsCount}` +
        '</li>',
    );
  } else {
    // eslint-disable-next-line prefer-rest-params
    oldUpdate.apply(this, arguments);
  }
};

// stripDiacritics code copied from select2
const Diacritics = $.fn.select2.amd.require('select2/diacritics');
function stripDiacritics(text) {
  // Used 'uni range + named function' from http://jsperf.com/diacritics/18
  function match(a) {
    return Diacritics[a] || a;
  }

  // eslint-disable-next-line no-control-regex -- copied from select2 code
  return text.replace(/[^\u0000-\u007E]/g, match);
}

/**
 * Matcher function for select2.
 *
 * This is the same as the code in select2, except that if an optgroup element
 * matches, then all its children will be shown as matches.
 */
export function select2GroupMatcher(params, data) {
  // Always return the object if there is nothing to compare
  if ($.trim(params.term) === '') {
    return data;
  }

  const original = stripDiacritics(data.text).toUpperCase();
  const term = stripDiacritics(params.term).toUpperCase();

  // Check if the text contains the term
  if (original.indexOf(term) > -1) {
    return data;
  }

  // Do a recursive check for options with children
  if (data.children && data.children.length > 0) {
    // Clone the data object if there are children
    // This is required as we modify the object to remove any non-matches
    const match = $.extend(true, {}, data);

    // Check each child of the option
    for (let c = data.children.length - 1; c >= 0; c -= 1) {
      const child = data.children[c];

      const matches = select2GroupMatcher(params, child);

      // If there wasn't a match, remove the object in the array
      if (matches == null) {
        match.children.splice(c, 1);
      }
    }

    // If any children matched, return the new object
    if (match.children.length > 0) {
      return match;
    }
  }

  // If it doesn't contain the term, don't return anything
  return null;
}
