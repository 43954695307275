import { Controller } from '@hotwired/stimulus';

/**
 * reset-form controller: clear target fields in a form.
 */
export default class extends Controller {
  static targets = ['field'];

  /**
   * Clear all the target fields in the element.
   */
  reset() {
    this.fieldTargets.forEach((element) => {
      element.value = '';

      // select2 needs the value change to be triggered
      $(element).trigger('change');

      // flatpickr needs to be cleared (because it makes a copy of the input)
      if (element.classList.contains('flatpickr-input')) {
        $(element).flatpickr().clear();
      }
    });
  }
}
