import { Controller } from '@hotwired/stimulus';

import * as spinner from './spinner';

/**
 * Show a spinner on the element.
 *
 * Data map options:
 * - show: if present (regardless of value), show the spinner.
 * - type: the spinner type (see startSpinner options).
 */
export default class extends Controller {
  static values = { show: Boolean, type: String };

  connect() {
    if (this.showValue) {
      this.start();
    }
  }

  disconnect() {
    spinner.stopSpinner(this.element);
  }

  start() {
    spinner.startSpinner(this.element, { type: this.typeValue });
  }

  stop() {
    spinner.stopSpinner(this.element);
  }
}
