import AjaxFormController from './ajax_form_controller';

/**
 * ajax-form--fetched-inline controller: manage a form (fetched from the server)
 * that temporarily replaces a static value.
 *
 * Allows a static view to be temporarily converted into a form to edit the
 * static data, and handles form submission and updating the static view with
 * the response from the server.
 *
 * The form contents are fetched from the server when editing mode is activated.
 *
 * Targets:
 * - appendContainer: if present, the form submission response will be added to
 *    this target's innerHTML.  Note that this is a security risk.
 * - editContainer: if present, the form submission response will be set as this
 *    target's  innerHTML.  Also used by startEditing and stopEditing.
 *    Note that this is a security risk.
 *
 * Values:
 * - loadFormUrl {string}: the URL to which a get request is sent when startEditing
 *    is called.  The response will be set as the editContainer element's inner HTML.
 */
export default class extends AjaxFormController {
  static targets = [...AjaxFormController.targets, 'editContainer'];

  static values = {
    ...AjaxFormController.values,
    loadFormUrl: String,
  };

  /**
   * Start editing the form.
   *
   * Gets the form HTML and sets it as the inner HTML of the editContainer.
   */
  async startEditing() {
    if (this.editing) {
      return;
    }

    const formHtml = await this.getFormHtml();
    if (formHtml) {
      this.editing = true;

      this.storedDisplayHtml = this.editContainerTarget.innerHTML;
      this.editContainerTarget.innerHTML = formHtml;

      this.focusFirstField();
    }
  }

  /**
   * Stop editing the form and replace the editContainer inner HTML with the
   * stored display html.
   */
  stopEditing() {
    if (!this.editing) {
      return;
    }

    this.editing = false;

    this.editContainerTarget.innerHTML = this.storedDisplayHtml;
  }

  /**
   * Gets the form HTML.
   *
   * Uses the loadFormUrl value to get the form HTML from the server.
   */
  async getFormHtml() {
    try {
      return await $.get(this.loadFormUrlValue);
    } catch (error) {
      this.handleErrorResponse(error, error.message);
      return null;
    }
  }

  /**
   * Show the successful form submission response.
   */
  handleSuccess(response) {
    this.storedDisplayHtml = response;
    this.stopEditing();

    super.handleSuccess(response);
  }

  get controllerName() {
    return 'ajax-form--fetched-inline';
  }
}
