import { Controller } from '@hotwired/stimulus';
import 'bootstrap/js/dist/scrollspy';

import { renderTemplate } from 'lib/render';
import imageTemplate from './image.html.eta';

const imageRegex = /\.(png|svg|jpg|jpeg|gif)$/;

/**
 * style-library controller.
 *
 * Apply scrollspy to body to enable side bar nav.
 */
export default class extends Controller {
  static targets = ['imagesContainer'];

  static values = { targetSelector: String };

  connect() {
    this.initializeScrollspy();

    this.insertImages();
  }

  disconnect() {
    $('body').scrollspy('dispose');
  }

  // Initialize the Bootstrap scrollspy plugin
  initializeScrollspy() {
    $('body').scrollspy({
      spy: 'scroll',
      target: this.targetSelectorValue,
      data_offset: 0,
    });
  }

  // Insert img tags for each image file
  insertImages() {
    if (this.hasImagesContainerTarget) {
      // Get images by using webpack to inspect the images directory.
      // Note we can't pass variables like imageRegex to require.context.
      const r = require.context('images', true, /\.(png|svg|jpg|jpeg|gif)$/);

      // Create an img element (within a container) for each image file
      r.keys().forEach((relativePath) => {
        if (imageRegex.test(relativePath)) {
          const title = relativePath.slice(2);
          const src = r(relativePath);

          if (src) {
            this.imagesContainerTarget.insertAdjacentHTML(
              'beforeend',
              renderTemplate(imageTemplate, { title, src }),
            );
          }
        }
      });
    }
  }
}
