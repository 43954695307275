import DOMPurify from 'dompurify';

const simpleFormatRE1 = /\r\n?/g;
const simpleFormatRE2 = /\n\n+/g;
const simpleFormatRE3 = /([^\n]\n)(?=[^\n])/g;

/**
 * Rough equivalent of Rail's simple_format:
 *
 * Returns text transformed into HTML using simple formatting rules. Two or more
 * consecutive newlines(\n\n or \r\n\r\n) are considered a paragraph and
 * wrapped in <p> tags. One newline (\n or \r\n) is considered a linebreak and
 * a <br /> tag is appended.  The html is sanitized.
 */
const simpleFormat = (str) => {
  let result = str || '';

  result = result.replace(simpleFormatRE1, '\n'); // \r\n and \r -> \n
  result = result.trim();

  result = result.replace(simpleFormatRE2, '</p>\n\n<p>'); // 2+ newline -> paragraph
  result = result.replace(simpleFormatRE3, '$1<br />'); // 1 newline -> br

  if (result) {
    result = DOMPurify.sanitize(`<p>${result}</p>`);
  }
  return result;
};

export default simpleFormat;
