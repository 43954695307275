import { Controller } from '@hotwired/stimulus';

/**
 * donations-form controller
 */
export default class extends Controller {
  static targets = [
    'amountField',
    'customAmountField',
    'customAmountFieldContainer',
  ];

  connect() {
    this.updateAmount();
  }

  updateAmount() {
    if (this.hasCustomAmountFieldContainerTarget) {
      const other = this.amountFieldTargets.some(
        (element) => element.checked && element.value === 'other',
      );

      this.customAmountFieldTarget.disabled = !other;
      this.customAmountFieldContainerTarget.classList.toggle('show', other);
    }
  }
}
