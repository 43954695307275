import { Controller } from '@hotwired/stimulus';

/**
 * image--variant controller: replace placeholder image loading animation
 * when image has completely loaded (or failed to load).
 */
export default class extends Controller {
  static targets = ['image', 'loader'];

  initialize() {
    this.retryCount = 0;
  }

  connect() {
    if (this.hasImageTarget && this.imageTarget.complete) {
      this.complete();
    }
  }

  complete() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.add('hide');
    }

    if (this.hasImageTarget) {
      this.imageTarget.classList.remove('hide');
    }
  }

  error() {
    setTimeout(() => {
      if (this.hasImageTarget && this.retryCount < 3) {
        const { src } = this.imageTarget;
        this.imageTarget.src = '';
        this.imageTarget.src = src;
        this.retryCount += 1;
        this.error();
      } else {
        this.complete();
      }
    }, 4000);
  }
}
