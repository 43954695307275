import { Controller } from '@hotwired/stimulus';
import SimpleLightbox from 'simplelightbox';

/**
 * image--lightbox controller: show images in a lightbox.
 */
export default class extends Controller {
  static targets = ['thumbnail'];

  connect() {
    this.simpleLightbox = new SimpleLightbox(this.thumbnailTargets, {
      animationSlide: false, // fade instead of slide between images
      animationSpeed: 150, // ms
      fadeSpeed: 100, // ms
      fileExt: false, // assume all src URLs represent showable images
      history: false, // don't update browser location history
      overlayOpacity: 0.9,
      scrollZoom: false,
      showCounter: false,
    });
  }

  disconnect() {
    this.simpleLightbox?.destroy();
    this.simpleLightbox = null;
  }
}
