import { Controller } from '@hotwired/stimulus';

/**
 * format--phone controller.
 *
 * Formats a phone number in an input to match "ddd-ddd-dddd [any extra text]",
 * or at least "ddd-[text]"
 */
export default class extends Controller {
  format(event) {
    event.target.value = this.formatPhone(event.target.value);
  }

  formatPhone(value) {
    if (!value) {
      return value;
    }

    // If user starts typing letters after at least three digits and a dash,
    // don't apply any further formatting to allow values like "800-FPF-ROCK"
    if (/\d{3}-.*[a-zA-Z]/.test(value)) {
      return value;
    }

    let digits = value.replace(/[^\d]/g, '');

    // Remove US country code (1) from front of digits
    if (/^1[0,2-9]/.test(digits)) {
      digits = digits.slice(1);
    }

    // Don't eagerly place a dash since that prevents deleting a dash when
    // deleting back through the text
    if (value.length <= 3) {
      return digits;
    }

    const firstSix = `${digits.slice(0, 3)}-${digits.slice(3, 6)}`;

    // Don't eagerly place a second dash since that prevents deleting a dash when
    // deleting back through the text
    if (value.length <= 7) {
      return firstSix;
    }

    const phone = `${firstSix}-${digits.slice(6, 9)}`;

    // Allow any text after the formatted phone number (e.g. an extension)
    const extra = value.slice(phone.length);

    return `${phone}${extra}`;
  }
}
