/**
 * A wrapper around a promise that may be resolved externally (i.e. outside the
 * Promise constructor scope). Useful when you need some object that you pass
 * around, while some other code waits for the object to be marked as completed
 * through its deferrer.
 *
 * Essentially a vanilla version of jQuery's Deferred factory function:
 * https://api.jquery.com/jquery.deferred/
 */
export default class Deferrer {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });

    /*
     * @param {Integer} timeout A timeout in milliseconds, after which the Deferrer
     *   will reject.
     */
    this.setTimeout = (timeout) => {
      const timer = window.setTimeout(() => {
        clearTimeout(timer);

        const error = new Error('[Deferrer] timed out');
        this.reject(error);
      }, timeout);
    };
  }
}
