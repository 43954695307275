import { Controller } from '@hotwired/stimulus';
import { csm } from './client_side_metrics';

/**
 * Parent class providing convenience methods to subclassed metric controllers
 */
export default class extends Controller {
  csm() {
    return csm;
  }
}
