import { Controller } from '@hotwired/stimulus';

/**
 * calendar--event--date-fields--recurrence controller: manage UI for setting recurring event logic
 */
export default class extends Controller {
  static targets = [
    'recurringCheckbox',
    'recurrenceFields',
    'periodSelect',
    'periodFields',
    'selectedPeriod',
    'weeklyFields',
    'monthlyFields',
    'monthlyUnitSelect',
    'monthlyUnitFields',
    'monthlyWeekFields',
    'monthlyDayFields',
  ];

  toggleRecurring() {
    this.recurrenceFieldsTarget.classList.toggle(
      'hide',
      !this.recurringCheckboxTarget.checked,
    );
  }

  changePeriod() {
    this.periodFieldsTargets.forEach((target) => {
      target.classList.add('hide');
    });
    const hash = { daily: 'day(s)', weekly: 'week(s)', monthly: 'month(s)' };
    const val = this.periodSelectTarget.value;
    this.selectedPeriodTarget.innerText = hash[val];
    if (val === 'weekly') this.weeklyFieldsTarget.classList.remove('hide');
    if (val === 'monthly') this.monthlyFieldsTarget.classList.remove('hide');
  }

  changeMonthlyUnit() {
    this.monthlyUnitFieldsTargets.forEach((target) => {
      target.classList.add('hide');
    });
    const val = this.monthlyUnitSelectTargets.find((el) => el.checked).value;
    if (val === 'week') this.monthlyWeekFieldsTarget.classList.remove('hide');
    if (val === 'month') this.monthlyDayFieldsTarget.classList.remove('hide');
  }

  connect() {
    this.toggleRecurring();
    this.changePeriod();
    this.changeMonthlyUnit();
  }
}
