import { Controller } from '@hotwired/stimulus';

/**
 * check-all controller: check all checkboxes.
 *
 * Optionally set the data-check-all-type on a control and some items to limit
 * which item targets are attached to that control.  Useful when there are
 * multiple sets of checkboxes in the same container.  The item data attribute
 * can either be a string (in which case it's matched exactly) or a JSON-serialized
 * array (in which case any value in the array may match the input control),
 */
export default class extends Controller {
  static targets = ['control', 'item'];

  connect() {
    this.controlTargets.forEach((control) => this.setControlChecked(control));
  }

  /**
   * Toggle checked state of items of the given event's target.
   */
  toggleItems(event) {
    const control = event.target;
    const items = this.controlItemTargets(control);

    items.forEach((item) => {
      item.checked = control.checked;
    });
  }

  /**
   * Returns item targets for the given control, optionally filtered by type
   * if the control has a data-check-all-type attribute.
   */
  controlItemTargets(control) {
    const items = this.itemTargets;

    const type = control.dataset.checkAllTypeValue;
    if (type) {
      return items.filter((item) => {
        // Get the matching item value (or values if a JSON-serialized array)
        const { checkAllTypeValue } = item.dataset;
        const itemTypes = checkAllTypeValue.startsWith('[')
          ? JSON.parse(checkAllTypeValue)
          : [checkAllTypeValue];

        return itemTypes.includes(type);
      });
    }

    return items;
  }

  /**
   * Sets the checked and indeterminate states of the given control based on
   * whether all, none, or some of the related item targets are checked.
   */
  setControlChecked(control) {
    const items = this.controlItemTargets(control);
    const checkedItems = items.filter((item) => item.checked);
    const uncheckedItems = items.filter((item) => !item.checked);

    control.checked = items.length === checkedItems.length;
    control.indeterminate =
      items.length !== checkedItems.length &&
      items.length !== uncheckedItems.length;
  }
}
