import { Controller } from '@hotwired/stimulus';
/**
 * field-changed controller: toggle visibility of elements depending on whether the value in the
 *  specified input has changed from its initial value.
 */
export default class extends Controller {
  static targets = ['input', 'changeDependent'];

  initialize() {
    this.startVal = this.inputTarget.value;
  }

  toggleElements() {
    this.changeDependentTargets.forEach((element) => {
      this.toggle(element, this.startVal !== this.inputTarget.value);
    });
  }

  toggle(element, active) {
    element.classList.toggle('show', active);
  }
}
