import { Controller } from '@hotwired/stimulus';

/**
 * list controller: append a templated element.
 *
 * Optional support for a max number of list items via:
 * - maxItems data map
 * - toggleAddButton
 */
export default class extends Controller {
  static targets = ['container', 'item', 'addButton'];

  static values = { template: String, replaceIndex: String, maxItems: Number };

  /**
   * Append the template value to the container target, and toggle the add button.
   *
   * If the replaceIndexValue data map is set, matching strings in the template
   * content will be replaced by a random value to ensure that each nested field
   * in a form has a unique name and id.
   */
  append(event) {
    event.preventDefault();

    let content = this.templateValue;

    // Replace [index_marker] with [timestamp] so that each nested field has
    // a unique hash index
    if (this.replaceIndexValue) {
      const newIndex = new Date().getTime();
      const indexRegex = new RegExp(this.replaceIndexValue, 'g');
      content = this.templateValue.replace(indexRegex, newIndex);
    }

    $(this.containerTarget).append($(content));
    this.toggleAddButton();
  }

  /**
   * Toggle display of the add button target via the 'show' class.
   *
   * Button is displayed if the active item count is less than the max items
   * setting, or if there is no max items setting.
   */
  toggleAddButton() {
    if (this.hasAddButtonTarget) {
      const show =
        !this.hasMaxItemsValue || this.activeItemCount < this.maxItemsValue;

      this.addButtonTarget.classList.toggle('show', show);
    }
  }

  /**
   * Should be called when an item is destroyed, i.e. hidden and marked for
   * destruction on form submit.  Toggles the add button.
   */
  destroyedItem(event) {
    event.target.dataset.listItemDestroyed = 'true';
    this.toggleAddButton();
  }

  /**
   * Returns the number of active items, i.e. the item targets that haven't been
   * marked for destruction.
   */
  get activeItemCount() {
    return this.itemTargets.filter(
      (element) => element.dataset.listItemDestroyed !== 'true',
    ).length;
  }
}
