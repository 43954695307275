import ValidateFieldController from '../field_controller';

/**
 * validate--maxlength controller: show warning when field content reaches maxlength.
 */
export default class extends ValidateFieldController {
  connect() {
    this.showValidationOptions = {
      ...this.showValidationOptions,
      state: 'warning',
    };
  }

  validate() {
    if (this.maxlength && this.fieldElement.value.length >= this.maxlength) {
      this.showValidation();
    } else {
      this.clearValidation();
    }
  }

  get maxlength() {
    const maxlength = this.fieldElement.getAttribute('maxlength');

    if (maxlength) {
      return parseInt(maxlength, 10);
    }

    return null;
  }

  get message() {
    return `You have reached the maximum limit of ${this.maxlength} characters.`;
  }
}
