import CancelledFormController from './cancelled_form_controller';

/**
 * csm--hide-email-author-form controller: Extends the behaviour of the cancelled
 * form controller by observing the visibility of the hide-email-author content,
 * so the metric dimension gets updated accordingly
 */
export default class extends CancelledFormController {
  userEmailContainer() {
    return $('div[data-posts--form-target="showUserEmailContainer"]');
  }

  getDimension(canOptOut) {
    return canOptOut ? 'EmailPrivacyEnabled' : 'EmailPrivacyDisabled';
  }

  updateDimension() {
    const canOptOut = this.userEmailContainer().is(':visible');

    // replace dimension with accurate value
    this.interactionCounter
      .withDimension(this.getDimension(canOptOut))
      .withoutDimension(this.getDimension(!canOptOut));
  }

  connect() {
    super.connect();

    $(document).on('csm:before-publish', () => {
      this.updateDimension();
    });
  }

  disconnect() {
    super.disconnect();

    // Detach event handlers
    $(document).off('csm:before-publish');
  }
}
