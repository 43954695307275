import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash/debounce';

import { renderTo } from 'lib/render';

/**
 * search--match controller: find models matching current form fields.
 *
 * Search for models that match the name of the input value, and show
 * matching results in the results element.
 *
 * Targets:
 * - matchInput: input value used for match
 * - resultsContainer: shown or hidden when results are available
 * - noResultsContainer: shown or hidden when no results are available
 * - results: render target for result elements
 * - unmatched: hidden when an existing model is selected (see select)
 * - matched: shown when an existing model is selected (see select)
 * - unmatchedDisabled: disabled when an existing model is not selected
 * - matchedDisabled: disabled when an existing model is selected
 */
export default class extends Controller {
  static targets = [
    'matchInput',
    'resultsContainer',
    'noResultsContainer',
    'results',
    'unmatched',
    'unmatchedDisabled',
    'matched',
    'matchedDisabled',
  ];

  static values = {
    disabled: Boolean,
    excludeId: Number,
    matchOnConnect: Boolean,
    url: String,
  };

  connect() {
    this.matchDebounced = debounce(this.match, 1000);

    if (this.matchOnConnectValue) {
      this.match();
    }
  }

  /**
   * Toggle whether this matcher is enabled.  If disabled, the results and match
   * are hidden.
   */
  toggleEnabled(enabled) {
    const disabled = !enabled;

    if (this.disabledValue !== disabled) {
      this.disabledValue = disabled;

      if (this.disabledValue) {
        this.toggleResults(false);
        this.toggleMatched(false);
      } else {
        this.match();
      }
    }
  }

  /**
   * Search for a match.
   */
  match() {
    if (this.disabledValue) {
      return;
    }

    if (this.returnEmpty) {
      this.showResults([], {});
      return;
    }

    const { value } = this.matchInputTarget;
    this.startRequest(value);
  }

  /**
   * Prematurely abort search if match input blank.
   * Subclasses with multiple inputs may override this.
   */
  get returnEmpty() {
    return !this.matchInputTarget.value;
  }

  /**
   * Start an ajax request to find matches.
   */
  startRequest(value) {
    $.ajax({
      type: this.requestType,
      url: this.urlValue,
      data: this.requestData(value),
    }).done((response) => {
      this.showResults(
        this.resultsFromResponse(response),
        this.dataFromResponse(response),
      );
    });
  }

  get requestType() {
    return 'GET';
  }

  requestData(value) {
    return {
      query: value,
      exclude_id: this.excludeIdValue,
    };
  }

  /**
   * Show match results in the results target.
   */
  showResults(results, data) {
    this.toggleResults(results.length > 0);
    this.toggleMatched(false);

    if (results.length > 0) {
      let results_ = results;
      if (this.includeBlank) {
        results_ = [{ id: '' }].concat(results);
      }

      renderTo(this.resultsTarget, this.matchResultsTemplate, {
        results: results_,
        data,
      });

      this.toggleMatched(false);
    }
  }

  toggleResults(show) {
    $(this.resultsContainerTarget).toggle(show);
    if (this.hasNoResultsContainerTarget) {
      $(this.noResultsContainerTarget).toggle(!show);
    }
  }

  /**
   * Trigger this when a match result is selected.
   */
  select(event) {
    this.toggleMatched(!!event.target.value);
  }

  toggleMatched(show) {
    $(this.unmatchedTargets).toggle(!show);
    $(this.matchedTargets).toggle(show);

    this.unmatchedDisabledTargets.forEach((element) => {
      element.disabled = !show;
    });
    this.matchedDisabledTargets.forEach((element) => {
      element.disabled = show;
    });
  }

  resultsFromResponse(response) {
    return response.results || [];
  }

  dataFromResponse(_response) {
    return {};
  }

  get includeBlank() {
    return true;
  }

  get matchResultsTemplate() {
    return null; // subclass responsibility
  }
}
