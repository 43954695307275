import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

/**
 * Pagination controller.
 *
 * Control page scrolling.
 */
export default class extends Controller {
  static targets = ['resultsContainer', 'button'];

  static values = { url: String, perPage: Number };

  initialize() {
    this.page = 1;
  }

  loadMore() {
    this.page += 1;
    Rails.ajax({
      type: 'get',
      url: this.urlValue,
      data: `page=${this.page}`,
      success: (response) => {
        this.append(response);
      },
    });
  }

  append(response) {
    // Set scrollTop position manually to ensure position does not change
    // after inserting content in Chrome
    const startScrollTop = document.documentElement.scrollTop;

    this.resultsContainerTarget.innerHTML += response.body.innerHTML;

    document.documentElement.scrollTop = startScrollTop;

    if (response.body.childElementCount < this.perPageValue) {
      this.buttonTarget.classList.add('hide');
    }
  }
}
