import { Controller } from '@hotwired/stimulus';
import Modal from 'bootstrap/js/dist/modal';

/**
 * registrations--welcome controller: show the welcome modal.
 */
export default class extends Controller {
  static values = { shown: Boolean };

  connect() {
    if (!this.shownValue) {
      new Modal(this.element).show();
      this.shownValue = true;
    }
  }
}
