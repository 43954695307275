import { Controller } from '@hotwired/stimulus';

/**
 * payments--credit-card controller: stripe integration for saving payment sources.
 */
export default class extends Controller {
  static targets = ['errorContainer'];

  connect() {
    this.initializeStripe();
  }

  initializeStripe() {
    this.stripe = this.Stripe(this.stripePublishableKeyValue);
  }

  toggleFieldsDisabled(disabled) {
    // Disable so cc data is not sent along with the request (for security)
    if (this.hasNumberContainerTarget) {
      this.numberContainerTarget.querySelector('input').disabled = disabled;
    }
    if (this.hasCvcContainerTarget) {
      this.cvcContainerTarget.querySelector('input').disabled = disabled;
    }
    if (this.hasExpiryContainerTarget) {
      this.expiryContainerTarget.querySelector('input').disabled = disabled;
    }
  }

  toggleError(message) {
    if (this.hasErrorContainerTarget) {
      this.errorContainerTarget.classList.toggle('show', !!message);
      this.errorContainerTarget.textContent = message || '';
    }
  }

  get Stripe() {
    return window.Stripe;
  }

  // LATER: instead of using gon, we should probably put this data into
  // controller element data attributes.
  get gon() {
    return window.gon;
  }

  get stripePublishableKeyValue() {
    return this.gon.stripe_publishable_key;
  }
}
