import { Controller } from '@hotwired/stimulus';
import 'highlight-within-textarea';

/**
 * highlight-within-textarea controller.
 *
 * Highlight content in a textarea
 *
 * https://github.com/lonekorean/highlight-within-textarea
 */
export default class extends Controller {
  static values = { content: Array };

  initialize() {
    $(this.element).highlightWithinTextarea({ highlight: this.contentValue });
  }
}
