import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

/**
 * sortable controller: reorderable drag-and-drop lists.
 *
 * Applies the SortableJS library to the element.
 *
 * https://github.com/SortableJS/Sortable
 * http://sortablejs.github.io/Sortable/
 *
 * Targets:
 * - form: If present, this form is submitted after an item is dropped.
 * - positionField {input}: If present, each sortable item's position is written
 *    to this field after an item is dropped.
 *
 * Values:
 * - handle {String}: Drag handle selector within list items.  Optional.
 *    https://github.com/SortableJS/Sortable#handle-option.
 * - forceFallback {Boolean}: Must be set to true for system tests to run
 *    drag & drop tests. Optional. Defaults to false
 *    https://github.com/rubycdp/cuprite/pull/182
 */
export default class extends Controller {
  static targets = ['form', 'positionField'];

  static values = {
    handle: String,
    forceFallback: { type: Boolean, default: false },
  };

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: this.handleValue,
      animation: 150,
      forceFallback: this.forceFallbackValue,
      store: {
        set: this.set.bind(this),
      },
    });
  }

  /**
   * Save the order of elements.  Called onEnd (when the item is dropped).
   *
   * https://github.com/SortableJS/Sortable#store
   */
  set() {
    this.positionFieldTargets.forEach((element, i) => {
      element.value = i + 1;
    });

    if (this.hasFormTarget) {
      this.formTarget.requestSubmit();
    }
  }
}
