// https://github.com/mailcheck/mailcheck#domains
export default [
  'accessvt.com',
  'aim.com',
  'anesu.org',
  'anwsu.org',
  'aol.com',
  'att.net',
  'bsdvt.org',
  'burlington.edu',
  'burlingtontelecom.net',
  'burlingtonvt.gov',
  'burton.com',
  'castleton.edu',
  'cathedralsquare.org',
  'ccsuvt.org',
  'cesuvt.org',
  'champlain.edu',
  'charter.net',
  'ci.burlington.vt.us',
  'colchestervt.gov',
  'comcast.net',
  'cox.net',
  'cs.com',
  'cssu.org',
  'cvuhs.org',
  'dartmouth.edu',
  'earthlink.net',
  'ezcloud.com',
  'fairpoint.net',
  'fcsuvt.org',
  'gisu.org',
  'gmail.com',
  'gmavt.com',
  'gmavt.net',
  'gmcr.com',
  'gmx.com',
  'greenmtn.edu',
  'hotmail.com',
  'howardcenter.org',
  'hughes.net',
  'icloud.com',
  'jsc.edu',
  'juno.com',
  'live.com',
  'luhs18.org',
  'mac.com',
  'madriver.com',
  'mail.com',
  'mail.smcvt.edu',
  'me.com',
  'middlebury.edu',
  'mpsvt.org',
  'msn.com',
  'myfairpoint.net',
  'mymail.champlain.edu',
  'netscape.net',
  'netzero.com',
  'netzero.net',
  'norwich.edu',
  'not.set',
  'optonline.net',
  'ossu.org',
  'outlook.com',
  'pshift.com',
  'riseup.net',
  'roadrunner.com',
  'rocketmail.com',
  'rockpoint.org',
  'sbcglobal.net',
  'sbschools.net',
  'shoreham.net',
  'smcvt.edu',
  'sover.net',
  'state.vt.us',
  'sterlingcollege.edu',
  'stoweaccess.com',
  'stu.norwich.edu',
  'student.u32.org',
  'surfglobal.net',
  'svcable.net',
  'tds.net',
  'together.net',
  'trans-video.net',
  'u32.org',
  'u61.net',
  'us.ibm.com',
  'uvm.edu',
  'uvmhealth.org',
  'verizon.net',
  'vermont.gov',
  'vermontel.net',
  'vtc.edu',
  'vtlink.net',
  'vtmednet.org',
  'wcvt.com',
  'wildblue.net',
  'winooski.k12.vt.us',
  'wwsu.org',
  'yahoo.com',
  'ymail.com',
];
