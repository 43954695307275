/* eslint-disable import/first, import/newline-after-import */

// js polyfills
// core-js and regenerator-runtime are automatically imported by the
// useBuiltIns: 'usage' option babel.config
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

// https://caniuse.com/?search=ResizeObserver
// used by @uppy/dashboard
// Safari 12 (support added in Safari 13.1)
import { ResizeObserver } from '@juggle/resize-observer';
if ('ResizeObserver' in window === false) {
  window.ResizeObserver = ResizeObserver;
}

// https://caniuse.com/?search=object.hasown
// used by @uppy/core during image upload
// Safari 12 - 14 (support added in Safari 15.4)
import 'core-js/proposals/accessible-object-hasownproperty';
