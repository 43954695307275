import { camelCase } from 'lodash/string';
import MetricFormController from './metric_form_controller';

/**
 * csm--cancelled-form controller: checks whether a form has been cancelled (aka abandoned)
 * and publishes a metric in this case. If the form has been cancelled after an interaction
 * then the metric name will be published with an "AfterInteraction" appendix
 *
 * Interaction is defined as the user has at least clicked on one field in the form (versus
 * the field value has changed, e.g. text has been entered - if we ever want this it would
 * be easy to change or to collect this as a separate metric)
 *
 * A specialFields selector can be defined to collect metrics about specific fields in a
 * form that we are worried about could lead to increased form interaction (e.g. users find
 * interaction with the field to confusing/cumbersome and give up)
 */
export default class extends MetricFormController {
  static values = {
    name: String,
    dimension: String,
    specialFieldsSelector: String,
  };

  connect() {
    this.interactionCounter = this.csm().addCounter(
      this.nameValue,
      this.dimensionValue,
    );

    // monitor form elements
    this.isPristine = true;
    this.getFormElements().focus(() => {
      if (this.isPristine) {
        this.isPristine = false;
        this.interactionCounter.name += 'AfterInteraction';
      }
    });

    // add interaction counters for every special field we are worried about could
    // lead to form cancellation. Each of these counters need to be added to plausible
    // as camel cased versions of the the field ID, e.g.
    // "newPostCancellationAfterInteractionWithPostImageUploads", otherwise they won't
    // be discoverable through the plausible dashboard
    this.fieldCounters = {};
    this.getForm()
      .find(this.specialFieldsSelectorValue)
      .focus((event) => {
        const field = event.target.id;
        if (field && !this.fieldCounters[field]) {
          this.fieldCounters[field] = this.csm().addCounter(
            camelCase(`${this.nameValue}_AfterInteractionWith_${field}`),
            this.dimensionValue,
          );
        }
      });

    // when form is submitted, consider it not abandoned, therefore delete
    // the prepared cancellation metrics
    this.getForm().submit(() => {
      this.csm().data.deleteMetric(this.interactionCounter);
      Object.values(this.fieldCounters).forEach((c) =>
        this.csm().data.deleteMetric(c),
      );
    });
  }

  disconnect() {
    // Detach event handlers
    this.getFormElements().off('focus');
    this.getForm().off('submit');
  }
}
